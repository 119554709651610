export const GET_REQUISITIONS = "GET_REQUISITIONS";
export const GET_REQUISITION_LOG = "GET_REQUISITION_LOG";

export const GET_REQUISITIONS_REQUEST = "GET_REQUISITIONS_REQUEST";
export const GET_REQUISITIONS_SUCCESS = "GET_REQUISITIONS_SUCCESS";
export const GET_REQUISITIONS_FAILURE = "GET_REQUISITIONS_FAILURE";

export const GET_REQUISITION_LOG_REQUEST = "GET_REQUISITION_LOG_REQUEST";
export const GET_REQUISITION_LOG_SUCCESS = "GET_REQUISITION_LOG_SUCCESS";
export const GET_REQUISITION_LOG_FAILURE = "GET_REQUISITION_LOG_FAILURE";

export const SET_REQUISITION_PAGE = 'SET_REQUISITION_PAGE';
