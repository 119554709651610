import { getAllOwnerships } from '~/api/ownership';
import { getAllEquipments as getAll } from '../../../api/equipment';
import { searchEquipments as searchKeyword } from '../../../api/equipment';
import { GET_EQUIPMENTS, SET_EQUIPMENT_PAGE } from './equipment.constants';

export const getAllEquipments = (pageNumber: number, pageSize: number) => {
  return {
    type: GET_EQUIPMENTS,
    promise: getAll(pageNumber, pageSize)
  };
};

export const searchEquipments = (search: string, pageNumber: number, pageSize: number) => {
  return {
    type: GET_EQUIPMENTS,
    promise: searchKeyword(search, pageNumber, pageSize)
  }
}

export const setPage = (page: number) => {
  return {
    type: SET_EQUIPMENT_PAGE,
    response: page
  };
};