import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { RefCallBack } from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { mergeRefs } from '~/utils/merge-ref';
import { timeFormatter } from '~/utils/date-time-utils';

export default function HourInput({
  error,
  helperText,
  onChange,
  inputRef,
  value
}: {
  error?: boolean;
  helperText?: string;
  inputRef?: RefCallBack;
  value?: string | null;
} & TextFieldProps) {
  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor]);

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined): void => {
    if (!event) return;
    setCursor(event.target.selectionStart === 3 ? 4 : event.target.selectionStart);
    onChange && onChange(event);
  };

  return (
    <>
      <TextField
        variant="outlined"
        inputRef={mergeRefs(ref, inputRef)}
        size="small"
        type="tel"
        value={timeFormatter(value)}
        error={error || false}
        helperText={helperText || ''}
        onChange={handleChange}
        sx={{
          maxWidth: 175
        }}
        placeholder="07:00"
        inputProps={{
          sx: {
            px: 1
          }
        }}
        InputProps={{
          sx: {
            pr: 0
          },
          startAdornment: (
            <InputAdornment position="start">
              <AccessTimeIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              <Divider flexItem orientation="vertical" sx={{ borderRightWidth: 2 }} />
              <InputAdornment position="end" sx={{ ml: 0 }}>
                <Typography component="div" variant="body2" color="text.secondary" sx={{ px: 2.5 }}>
                  HRS
                </Typography>
              </InputAdornment>
            </>
          )
        }}
      />
    </>
  );
}
