import { UserSchemaType } from '../../../models/User';
import { GET_CURRENT_USER_FAILURE, GET_CURRENT_USER_REQUEST, GET_CURRENT_USER_SUCCESS } from './user.constants';

interface UserState {
  userInfo?: UserSchemaType;
  getUserLoading: boolean;
}

const initialState: UserState = {
  getUserLoading: false
};

const userReducer = (state = initialState, action: { response?: any; type?: any }): UserState => {
  const { type } = action;

  switch (type) {
    case GET_CURRENT_USER_REQUEST:
      return { ...state, getUserLoading: true };
    case GET_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        userInfo: action.response.data,
        getUserLoading: false
      };
    }
    case GET_CURRENT_USER_FAILURE:
      return { ...state, getUserLoading: false };

    default:
      return state;
  }
};

export default userReducer;
