import { AxiosError, AxiosResponse } from 'axios';
import {
  LubricantCreateType,
  LubricantEditType,
  LubricantStockInCreateType,
  LubricantStockOutCreateType,
  LubricantTypeCreateType
} from '~/models/Lubricant';
import { axiosPrivate } from './axios';

export const getAllLubricants = (pageNumber: number = 0, pageSize: number = 5) => {
  return axiosPrivate
    .get(`/lubricant?page=${pageNumber}&perPage=${pageSize}`)
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const getAllLubricantTypes = (pageNumber: number = 0, pageSize: number = 50) => {
  return axiosPrivate
    .get(`/lubricant/types?page=${pageNumber}&perPage=${pageSize}`)
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const addLubricantType = (data: LubricantTypeCreateType) => {
  return axiosPrivate
    .post('/lubricant/types', data)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const addLubricant = (lubricantData: LubricantCreateType) => {
  return axiosPrivate
    .post('/lubricant', lubricantData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const updateLubricant = (lubricantData: LubricantEditType) => {
  return axiosPrivate
    .patch('/lubricant', lubricantData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const deleteLubricant = (lubricantId: string) => {
  return axiosPrivate
    .delete(`/lubricant/${lubricantId}`)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const stockIn = (stockInData: LubricantStockInCreateType) => {
  return axiosPrivate
    .post('/lubricant/stockin', stockInData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const stockOut = (stockOutData: LubricantStockOutCreateType) => {
  return axiosPrivate
    .post('/lubricant/stockout', stockOutData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};
