import { useState } from 'react';
import { PrincipalReturnType } from '~/models/Principal';
import { getAllPrincipals } from '~/api/principals';

export function usePrincipals(page: number, perPage: number) {
  const [pageN, setPageN] = useState(page);
  const [pageSize, setPageSize] = useState(perPage);
  const [loading, setLoading] = useState(false);
  const [principalData, setPrincipalData] = useState<PrincipalReturnType>({ principalsList: [], totalCount: { count: 0 } });

  const getPrincipals = async () => {
    setLoading(true);
    const result = await getAllPrincipals(pageN, pageSize);
    setPrincipalData(result);
    setLoading(false);
  };

  const getNextPage = async () => {
    const total = principalData?.totalCount?.count;
    if (total && total > (pageN + 1) * pageSize) {
      setLoading(true);
      const result = await getAllPrincipals(pageN + 1, pageSize);
      setPageN((pagen) => pagen + 1);
      setPrincipalData((list) => ({ ...list, principalData: [...list.principalsList, ...result.principalData] }));
      setLoading(false);
    }
  };

  return {
    principalData,
    loading,
    getPrincipals,
    getNextPage
  };
}
