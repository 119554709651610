import React from 'react';
import Table, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Vehicle } from '~/models/Equipment';
import VehicleListTableRow from './VehicleListTableRow';

const headCells: TableCellProps[] = [
  {
    align: 'center',
    children: 'Sl. No.'
  },
  {
    align: 'left',
    children: 'Vehicle No.'
  },
  {
    align: 'center',
    children: 'Ownership'
  },
  {
    align: 'center',
    children: 'Insurance'
  },
  {
    align: 'center',
    children: 'Pollution'
  },
  {
    align: 'center',
    children: 'Availability'
  },
  {
    align: 'center',
    children: 'Action'
  }
];

export default function VehicleListTable({
  vehicleList,
  ...props
}: {
  vehicleList?: Vehicle[];
} & TableProps) {

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table {...props}>
          <TableHead>
            <TableRow>
              {headCells.map((row, index) => (
                <TableCell key={index} align={row.align}>
                  <Typography sx={{ fontWeight: 500, color: 'text.secondary' }}>{row.children}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {vehicleList?.map((vehicle, vIndex) => (
              <VehicleListTableRow key={vehicle?._id} vehicle={vehicle} vIndex={vIndex} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
