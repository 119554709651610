import { ReactNode, useState, useRef, Children, isValidElement, FormEventHandler } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';

type Selected = string | number | null | undefined;

export default function SelectButton({
  label,
  children,
  startIcon,
  endIcon,
  disablePortal = false,
  onItemSelect,
  ...props
}: {
  disablePortal?: boolean;
  label: ReactNode;
  startIcon?: ReactNode | ((open: boolean) => ReactNode);
  endIcon?: ReactNode | ((open: boolean) => ReactNode);
  onItemSelect?: (selected: Selected, event: FormEventHandler<HTMLButtonElement> | undefined) => void;
} & Omit<ButtonProps, 'ref' | 'onClick' | 'startIcon' | 'endIcon'>) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleSelect = (value: Selected) => {
    setOpen(false);
  };

  const getEndIcon = () => {
    if (typeof endIcon === 'function') return endIcon(open);
    if (endIcon) return endIcon;

    return (
      <KeyboardArrowDownIcon
        fontSize="small"
        sx={(theme) => ({
          transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.short
          }),
          transform: open ? 'rotate(-180deg)' : 'rotate(0)',
          color: theme.palette.text.primary
        })}
      />
    );
  };

  const getStartIcon = () => {
    if (typeof startIcon === 'function') return startIcon(open);
    if (startIcon) return startIcon;
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={handleToggle}
        aria-controls={open ? 'select-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="menu"
        startIcon={getStartIcon()}
        endIcon={getEndIcon()}
        {...props}>
        <Typography component="div" color="text.primary">
          {label}
        </Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={disablePortal}
        sx={{ minWidth: anchorRef.current?.clientWidth, zIndex: 99 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="select-button-menu" autoFocusItem>
                  {Children.map(children, (child) => {
                    if (isValidElement(child)) {
                      return {
                        ...child,
                        props: {
                          ...child.props,
                          onClick: (event: FormEventHandler<HTMLButtonElement> | undefined) => {
                            handleSelect(child.props.value);
                            if (typeof onItemSelect === 'function') {
                              onItemSelect(child.props.value, event);
                            }
                            if (typeof child.props.onClick === 'function') {
                              child.props?.onClick(event);
                            }
                          }
                        }
                      };
                    }
                    return child;
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
