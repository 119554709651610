import { axiosPrivate } from './axios';
import { AxiosError, AxiosResponse } from 'axios';
import { VehicleCreateType, VehicleDeleteType, EquipmentDeleteType, VehicleUpdateType, EquipmentCreateType } from '~/models/Equipment';

export const getAllEquipments = (pageNumber: number, pageSize: number) => {
  return axiosPrivate
    .get(`/equipment?page=${pageNumber}&perPage=${pageSize}`)
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const searchEquipments = (search: string, pageNumber: number, pageSize: number) => {
  return axiosPrivate
    .get(`/equipment/search?keyword=${search}&page=${pageNumber}&perPage=${pageSize}`)
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const addEquipment = (equipmentData: EquipmentCreateType) => {
  return axiosPrivate
    .post('/equipment', equipmentData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const addVehicle = (vehicleData: VehicleCreateType) => {
  return axiosPrivate
    .post('/equipment/vehicle', vehicleData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const updateVehicle = (vehicleData: VehicleUpdateType) => {
  return axiosPrivate
    .patch('/equipment/vehicle', vehicleData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const deleteVehicle = (vehicleData: VehicleDeleteType) => {
  return axiosPrivate
    .delete('/equipment/vehicle', { data: vehicleData })
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const deleteEquipment = (equipmentData: EquipmentDeleteType) => {
  return axiosPrivate
    .delete('/equipment', { data: equipmentData })
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};
