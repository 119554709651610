import React, { Suspense, useState } from 'react';
import MainScreen from '../../MainScreen/View/MainScreen';
import styles from './Maintenance.module.scss';

import MaintenanceListTable from '../Components/MaintenanceListTable';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Search from '~/components/Search';
import MaintenanceFilter from '../Components/MaintenanceFilter';
import { setSearchValue } from '../redux/maintenance.actions';
import { useDispatch } from 'react-redux';

const MaintenanceForm = React.lazy(() => import('../Components/MaintenanceForm'));

export function Maintenance() {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <MainScreen title="Maintenance">
        <div>
          <Box component="div" display="flex" flexDirection="column">
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  alignItems: 'start',
                  gap: 3,
                  px: 0,
                  mx: 3
                },
                flexDirection: 'row',
                alignItems: 'center',
                gap: 0,
                p: 3.75
              })}>
              <Box width={{ xs: '100%', sm: '100%', md: 'auto' }}>
                <Search onSearch={(value) => dispatch(setSearchValue(value))} showFilter filter={<MaintenanceFilter />} />
              </Box>
              <Box
                display="flex"
                sx={(theme) => ({
                  [theme.breakpoints.down('md')]: { gap: 2, flexDirection: 'column-reverse' },
                  gap: 5,
                  flexDirection: 'row'
                })}>
                <Stack direction="row" gap={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-capitalize"
                    sx={{
                      width: 'fit-content',
                      height: 50,
                      borderRadius: 2
                    }}
                    startIcon={<AddIcon fontSize="small" />}
                    disableElevation
                    onClick={() => setShowCreateForm(true)}>
                    Add New
                  </Button>
                </Stack>
              </Box>
            </Box>
            <MaintenanceListTable />
          </Box>
        </div>
      </MainScreen>

      <Suspense>{showCreateForm && <MaintenanceForm mode="create" isOpen handleClose={() => setShowCreateForm(false)} />}</Suspense>
    </>
  );
}
