import { useState, useRef, ChangeEvent, useEffect, ReactNode } from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import useDebounce from '~/hooks/useDebounce';

export default function Search({
  onSearch,
  showFilter = false,
  filter
}: {
  onSearch?: (debouncedValue: string) => void;
  showFilter?: boolean;
  filter?: ReactNode;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(value, 700);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    onSearch && onSearch(debouncedValue);
  }, [debouncedValue]);

  return (
    <>
      <Box
        ref={anchorRef}
        border={2}
        borderColor={(theme) => theme.palette.border.main}
        borderRadius={2}
        component="form"
        display="flex"
        alignItems="center"
        width={{ md: '100%', lg: 505 }}
        height={50}>
        <SearchIcon sx={(theme) => ({ my: 2.125, mx: 2.75, color: theme.palette.text.secondary })} fontSize="small" />
        <InputBase
          sx={(theme) => ({ flex: 1, fontSize: theme.typography.body1.fontSize, mr: 1 })}
          placeholder="Search"
          inputProps={{ 'aria-label': 'search', inputMode: 'search' }}
          value={value}
          onChange={handleChange}
        />
        {showFilter && (
          <>
            <Divider
              orientation="vertical"
              sx={(theme) => ({
                color: theme.palette.border.main,
                mr: 2.5
              })}
            />
            <Button
              aria-controls={open ? 'filter-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="filter"
              aria-haspopup="menu"
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={handleToggle}
              sx={{
                textTransform: 'none',
                mr: 1.5,
                '&:hover': {
                  bgcolor: 'transparent'
                }
              }}
              endIcon={
                <KeyboardArrowDownIcon
                  fontSize="small"
                  sx={(theme) => ({
                    transition: theme.transitions.create(['transform'], {
                      duration: theme.transitions.duration.short
                    }),
                    transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                    color: theme.palette.text.secondary
                  })}
                />
              }>
              <Typography color="text.secondary">Filters</Typography>
            </Button>
          </>
        )}
      </Box>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition sx={{ minWidth: anchorRef.current?.clientWidth }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {filter}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
