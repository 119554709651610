import { getAllRequisitions as getAll } from '../../../api/requisitions';
import { getRequisitionLog as getLog } from '../../../api/requisitionLog';
import { GET_REQUISITIONS, GET_REQUISITION_LOG, SET_REQUISITION_PAGE } from './requisition.constants';

export const getAllRequisitions = (pageNumber: number, pageSize: number) => {
  return {
    type: GET_REQUISITIONS,
    promise: getAll(pageNumber, pageSize)
  };
};

export const setPage = (page: number) => {
  return {
    type: SET_REQUISITION_PAGE,
    response: page
  };
};

export const getRequisitionLog = (requisitionId: string) => {
  return {
    type: GET_REQUISITION_LOG,
    promise: getLog(requisitionId),
    requisitionId
  };
};
