import format from 'date-fns/format';

const MASK_USER_INPUT_SYMBOL = '_';
const DATE_FORMAT = 'dd/MM/yyyy';
const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';

export const formatDateString = (date: string) => {
  return date ? format(new Date(date), DATE_FORMAT) : '-';
};

export const formatDateTimeString = (datetime: string) => {
  return datetime ? format(new Date(datetime), DATE_TIME_FORMAT) : '-';
};

export const maskedDateFormatter = (mask: string, acceptRegexp: RegExp) => (value: string) => {
  let outputCharIndex = 0;
  return value
    .split('')
    .map((char, inputCharIndex) => {
      acceptRegexp.lastIndex = 0;

      if (outputCharIndex > mask.length - 1) {
        return '';
      }

      const maskChar = mask[outputCharIndex];
      const nextMaskChar = mask[outputCharIndex + 1];

      const acceptedChar = acceptRegexp.test(char) ? char : '';
      const formattedChar = maskChar === MASK_USER_INPUT_SYMBOL ? acceptedChar : maskChar + acceptedChar;

      outputCharIndex += formattedChar.length;

      const isLastCharacter = inputCharIndex === value.length - 1;
      if (isLastCharacter && nextMaskChar && nextMaskChar !== MASK_USER_INPUT_SYMBOL) {
        // when cursor at the end of mask part (e.g. month) prerender next symbol "21" -> "21/"
        return formattedChar ? formattedChar + nextMaskChar : '';
      }

      return formattedChar;
    })
    .join('');
};

export const timeFormatter = (value: string | null | undefined) => {
  if (!value) return '';
  const formatterFn = maskedDateFormatter('__:__', /[\d]/gi);

  return formatterFn(value);
};

export const parseTimeString = (value: string | null | undefined) => {
  if (!value) return { time: '', ampm: 'AM' };
  const res = value.split(' ');
  return {
    time: res[0],
    ampm: res[1]
  };
};

export const convertTime12to24 = (time12h: string) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes]: (string | number)[] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  if (typeof hours === 'string') {
    hours = parseInt(hours);
  }
  minutes = parseInt(minutes);

  return {
    hours,
    minutes
  };
};
