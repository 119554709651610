import { boolean, number, object, string, enum as zodEnum, infer as Infer } from 'zod';
import { EquipmentSchema } from './Equipment';
import { MechanicSchema } from './Mechanic';
import { PrincipalSchema } from './Principal';
import { VesselSchema } from './Vessel';

export const timeValidation = string().regex(/^(1[0-2]|0?[1-9]):([0-5]?[0-9]) [AP]M$/, { message: 'Please enter a valid time' });

export const hourValidation = string().regex(/^(1[0-2]|0?[1-9]):([0-5]?[0-9])$/, { message: 'Please enter a valid time' });

export const RequisitionSchema = object({
  _id: string(),
  requisitionId: string().optional(),
  createdAt: string().min(1).optional(),
  equipment: EquipmentSchema.omit({ avatar: true }).optional(),
  givenBy: string().optional(),
  receivedBy: string().optional(),
  ac: string(),
  givenAt: timeValidation,
  receivedAt: timeValidation,
  vessel: VesselSchema.omit({ createdAt: true, updatedAt: true }).optional(),
  principal: PrincipalSchema.omit({ createdAt: true, updatedAt: true }).optional(),
  cargo: string(),
  marchingTime: hourValidation,
  operator: string().optional(),
  reportingTime: timeValidation,
  status: string().optional()
});

export const RequisitionLogSchema = object({
  _id: string(),
  logData: object({
    place_of_work: string().min(1),
    startingTime: timeValidation,
    closingTime: timeValidation,
    isIdle: boolean(),
    workDetails: string().min(1),
    breakdownDetails: string().min(1).nullable().optional(),
    remarks: string().min(1).nullable().optional()
  })
});

export const RequisitionLogFormSchema = object({
  placeOfWork: object({
    _id: string(),
    name: string()
  }),
  startingTime: timeValidation,
  closingTime: timeValidation,
  isIdle: boolean(),
  workDetails: string().min(1),
  breakdownDetails: string().min(1).nullable().optional(),
  remarks: string().min(1).nullable().optional()
});

export const RequisitionLogCreateSchema = object({
  requisitionId: string(),
  placeOfWorkId: string(),
  startingTime: timeValidation,
  closingTime: timeValidation,
  isIdle: boolean(),
  workDetails: string().min(1),
  breakdownDetails: string().min(1).nullable().optional(),
  remarks: string().min(1).nullable().optional()
});

export const RequisitionLogUpdateSchema = RequisitionLogCreateSchema.merge(object({ logId: string() }));

export const RequisitionDeleteSchema = object({ requisitionId: string() });
export const RequisitionLogDeleteSchema = object({ logId: string() });
export const RequisitionCreateSchema = RequisitionSchema.omit({
  _id: true
});
export const RequisitionUpdateSchema = RequisitionCreateSchema.merge(object({ requisitionId: string() }));

export type Requisition = Infer<typeof RequisitionSchema>;
export type RequisitionLog = Infer<typeof RequisitionLogSchema>;

export const RequisitionFormSchema = RequisitionSchema.omit({ createdAt: true });
export type RequisitionFormType = Infer<typeof RequisitionFormSchema>;

export type RequisitionLogFormType = Infer<typeof RequisitionLogFormSchema>;
export type RequisitionLogCreateType = Infer<typeof RequisitionLogCreateSchema>;
export type RequisitionLogUpdateType = Infer<typeof RequisitionLogUpdateSchema>;
export type RequisitionLogDeleteType = Infer<typeof RequisitionLogDeleteSchema>;

export type RequisitionDeleteType = Infer<typeof RequisitionDeleteSchema>;
export type RequisitionCreateType = Infer<typeof RequisitionCreateSchema>;
export type RequisitionUpdateType = Infer<typeof RequisitionUpdateSchema>;
