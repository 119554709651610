import { AxiosError, AxiosResponse } from 'axios';
import { axiosPrivate } from './axios';
import { MaintenanceCreateType, MaintenanceEditType } from '~/models/Maintenance';

export const getAllMaintenanceReqs = (pageNumber: number = 0, pageSize: number = 5, searchParams?: Record<string, string>) => {
  let params: Record<string, any> = {
    page: pageNumber,
    perPage: pageSize
  };
  if (searchParams) {
    params['search'] = Object.keys(searchParams);
    for (const [key, value] of Object.entries(searchParams)) {
      params[key] = value;
    }
  }
  return axiosPrivate
    .get('/maintenance', {
      params: params
    })
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const addMaintenance = (maintenanceData: MaintenanceCreateType) => {
  return axiosPrivate
    .post('/maintenance', maintenanceData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const updateMaintenance = (maintenanceData: MaintenanceEditType) => {
  return axiosPrivate
    .patch('/maintenance', maintenanceData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const deleteMaintenance = (maintenanceId: string) => {
  return axiosPrivate
    .delete(`/maintenance/${maintenanceId}`)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const getAllMaintenanceTypes = (pageNumber: number = 0, pageSize: number = 5) => {
  return axiosPrivate
    .get(`/maintenance/types?page=${pageNumber}&perPage=${pageSize}`)
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const getAllMaintenanceServices = (pageNumber: number = 0, pageSize: number = 5) => {
  return axiosPrivate
    .get(`/maintenance/services?page=${pageNumber}&perPage=${pageSize}`)
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};
