import React, { useState } from 'react';
import MainScreen from '../../MainScreen/View/MainScreen';
import styles from './Equipment.module.scss';

import EquipmentListTable from '../Components/EquipmentListTable';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import VehicleForm from '../Components/VehicleForm';
import CircleIcon from '@mui/icons-material/Circle';
import { Typography } from '@mui/material';
import Search from '~/components/Search';

export function Equipment() {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [equipmentSearchKeyword, setEquipmentSearchKeyword] = useState<string>('');

  return (
    <>
      <MainScreen title="Equipment">
        <div>
          <Box component="div" display="flex" flexDirection="column">
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  alignItems: 'start',
                  gap: 3,
                  px: 0,
                  mx: 3
                },
                flexDirection: 'row',
                alignItems: 'center',
                gap: 0,
                p: 3.75
              })}>
              <Box width={{ xs: '100%', sm: '100%', md: 'auto' }}>
                <Search onSearch={(value) => setEquipmentSearchKeyword(value)} />
              </Box>
              <Box
                display="flex"
                sx={(theme) => ({
                  [theme.breakpoints.down('md')]: { gap: 2, flexDirection: 'column-reverse' },
                  gap: 5,
                  flexDirection: 'row'
                })}>
                <Stack direction="row" alignItems="center" gap={2.5}>
                  <Box component="div" gap="10px" display="flex" alignItems="center">
                    <CircleIcon color="success" sx={{ fontSize: 10 }} />
                    <Typography color="text.secondary">Free</Typography>
                  </Box>
                  <Box component="div" gap="10px" display="flex" alignItems="center">
                    <CircleIcon color="error" sx={{ fontSize: 10 }} />
                    <Typography color="text.secondary">Active</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" gap={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-capitalize"
                    sx={{
                      width: 'fit-content',
                      height: 50,
                      borderRadius: 2
                    }}
                    startIcon={<AddIcon fontSize="small" />}
                    disableElevation
                    onClick={() => setShowCreateForm(true)}>
                    Add New
                  </Button>
                </Stack>
              </Box>
            </Box>
            <EquipmentListTable searchValue={equipmentSearchKeyword} />
          </Box>
        </div>
      </MainScreen>

      <VehicleForm
        mode="create"
        isOpen={showCreateForm}
        handleClose={() => setShowCreateForm(false)}
        data={{
          number: '',
          _id: '',
          equipment: {
            _id: '',
            code: '',
            name: ''
          },
          ownership: '',
          insuranceExpDate: new Date(),
          pollutionExpDate: new Date(),
          availability: true
        }}
      />
    </>
  );
}
