export function getCurrentUrl(location: any) {
  return location.pathname.split(/[?#]/)[0];
}

export function checkIsActive(location: any, url: string) {
  const current = getCurrentUrl(location);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  return false;
}

export function checkSubMenuIsActive(location: any, url: string) {
  const current = getCurrentUrl(location);
  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
}
