import { axiosPrivate } from './axios';
import { AxiosError, AxiosResponse } from 'axios';
import { RequisitionDeleteType, RequisitionCreateType, RequisitionUpdateType } from '~/models/Requisition';

export const getAllRequisitions = (pageNumber: number, pageSize: number) => {
  return axiosPrivate
    .get(`/requisition?page=${pageNumber}&perPage=${pageSize}`)
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const addRequisition = (requisitionData: RequisitionCreateType) => {
  return axiosPrivate
    .post('/requisition', requisitionData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const updateRequisition = (requisitionData: RequisitionUpdateType) => {
  return axiosPrivate
    .patch('/requisition', requisitionData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const deleteRequisition = (requisitionData: RequisitionDeleteType) => {
  return axiosPrivate
    .delete('/requisition', { data: requisitionData })
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};
