import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Dashboard } from '../presentation/Dashboard/index';
import { Requisition } from '../presentation/Requisition/index';
import { Equipment } from '../presentation/Equipment/index';
import { Lubricant } from '../presentation/Lubricant';
import { SparePart } from '../presentation/SparePart';
import { Maintenance } from '../presentation/Maintenance';
import { Authenticate } from '~/presentation/Authenticate/index';
import AppContentWrapper from '~/presentation/core/AppContentWrapper';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import history from '~/utils/customHistory';

export function PublicRoutes() {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/auth/login" element={<Authenticate />} />

        <Route path="/dashboard" element={<AppContentWrapper />}>
          <Route index element={<Dashboard />} />
          <Route path="requisition" element={<Requisition />} />
          <Route path="equipment" element={<Equipment />} />
          <Route path="inventory">
            <Route path="lubricant" element={<Lubricant />} />
            <Route path="sparepart" element={<SparePart />} />
          </Route>

          <Route path="maintenance" element={<Maintenance />} />
        </Route>

        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </HistoryRouter>
  );
}
