import { MaintenanceDataSchemaType } from '../../../models/Maintenance';
import {
  GET_MAINTENANCE_REQS_FAILURE,
  GET_MAINTENANCE_REQS_REQUEST,
  GET_MAINTENANCE_REQS_SUCCESS,
  SET_MAINTENANCE_PAGE,
  SET_MAINTENANCE_PER_PAGE,
  SET_SEARCH_KEYS,
  SET_SEARCH_VALUE
} from './maintenance.constants';

interface MaintenanceState {
  maintenanceReqList: MaintenanceDataSchemaType;
  loading: boolean;
  page: number;
  perPage: number;
  searchKeys?: string | string[];
  searchValue?: string;
}

const initialState: MaintenanceState = {
  maintenanceReqList: { maintenancesList: [], totalCount: { count: 0 } },
  loading: false,
  page: 0,
  perPage: 5,
  searchKeys: ['service', 'number']
};

const maintenanceReducer = (state = initialState, action: { response?: any; type?: any }): MaintenanceState => {
  const { type } = action;

  switch (type) {
    case GET_MAINTENANCE_REQS_REQUEST:
      return { ...state, loading: true };
    case GET_MAINTENANCE_REQS_SUCCESS: {
      return {
        ...state,
        maintenanceReqList: action.response,
        loading: false
      };
    }
    case GET_MAINTENANCE_REQS_FAILURE:
      return { ...state, loading: false };

    case SET_MAINTENANCE_PAGE:
      return { ...state, page: action.response };

    case SET_MAINTENANCE_PER_PAGE:
      return { ...state, perPage: action.response };

    case SET_SEARCH_KEYS:
      return { ...state, searchKeys: action.response };

    case SET_SEARCH_VALUE:
      return { ...state, searchValue: action.response };

    default:
      return state;
  }
};

export default maintenanceReducer;
