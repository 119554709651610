export const GET_MAINTENANCE_REQS = 'GET_MAINTENANCE_REQS';

export const GET_MAINTENANCE_REQS_REQUEST = 'GET_MAINTENANCE_REQS_REQUEST';
export const GET_MAINTENANCE_REQS_SUCCESS = 'GET_MAINTENANCE_REQS_SUCCESS';
export const GET_MAINTENANCE_REQS_FAILURE = 'GET_MAINTENANCE_REQS_FAILURE';

export const SET_MAINTENANCE_PAGE = 'SET_MAINTENANCE_PAGE';
export const SET_MAINTENANCE_PER_PAGE = 'SET_MAINTENANCE_PER_PAGE';

export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_SEARCH_KEYS = 'SET_SEARCH_KEYS';
