import React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function MainScreen({
  title,
  hasPaperWrap = true,
  buttons,
  children
}: {
  title?: React.ReactNode;
  hasPaperWrap?: boolean;
  buttons?: React.ReactNode[];
  children?: React.ReactNode;
}) {
  return (
    <Stack flexDirection="column" gap={5} sx={{ mx: 4 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {title && (
          <Typography component="div" fontSize={20} fontWeight={700} sx={{ mt: 2.5 }}>
            {title}
          </Typography>
        )}
        {buttons && <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
          {buttons}
        </Stack>}
      </Stack>
      {hasPaperWrap ? <Paper sx={{ pb: 20, mb: 50, borderRadius: 2 }}>{children}</Paper> : children}
    </Stack>
  );
}
