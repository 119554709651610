import { object, string, number, infer as Infer, array } from 'zod';

export const MaintenanceRequestSchema = object({
  id: string(),
  number: string().min(1),
  serviceNumber: string().min(1),
  details: string().min(1),
  type: string().min(1),
  status: string()
});
export type MaintenanceRequest = Infer<typeof MaintenanceRequestSchema>;

export const MaintenanceRequestFormSchema = MaintenanceRequestSchema.omit({ id: true });
export type MaintenanceRequestFormType = Infer<typeof MaintenanceRequestFormSchema>;

export const MaintenanceSchema = object({
  _id: string(),
  number: string(),
  detail: string(),
  createdAt: string(),
  updatedAt: string(),
  service: string(),
  type: string(),
  status: string()
});
export type MaintenanceSchemaType = Infer<typeof MaintenanceSchema>;

export const MaintenanceDataSchema = object({
  maintenancesList: array(MaintenanceSchema),
  totalCount: object({
    count: number()
  })
});
export type MaintenanceDataSchemaType = Infer<typeof MaintenanceDataSchema>;

export const MaintenanceFormSchema = object({
  number: string().min(1, 'Number is required'),
  service: string().min(1, 'Service is required'),
  type: string().min(1, 'Type is required'),
  detail: string().min(1, 'Detail cannot be empty'),
  status: string()
});
export type MaintenanceFormType = Infer<typeof MaintenanceFormSchema>;
export type MaintenanceCreateType = Infer<typeof MaintenanceFormSchema>;

const MaintenanceEditSchema = MaintenanceFormSchema.and(object({ maintenanceId: string() }));
export type MaintenanceEditType = Infer<typeof MaintenanceEditSchema>;

export const MaintenanceServiceSchema = object({
  _id: string(),
  number: string(),
  createdAt: string(),
  updatedAt: string()
});

export type MaintenanceServiceSchemaType = Infer<typeof MaintenanceServiceSchema>;
export type MaintenanceServiceReturnType = {
  maintenanceServicesList: MaintenanceServiceSchemaType[];
  totalCount: {
    count: number;
  };
};

export const MaintenanceTypeSchema = object({
  _id: string(),
  name: string(),
  createdAt: string(),
  updatedAt: string()
});

export type MaintenanceTypeSchemaType = Infer<typeof MaintenanceTypeSchema>;
export type MaintenanceTypeReturnType = {
  maintenanceTypesList: MaintenanceTypeSchemaType[];
  totalCount: {
    count: number;
  };
};
