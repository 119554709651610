import { Divider } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { DesktopDatePicker as MuiDatePicker, DesktopDatePickerProps as DatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker';
import { Children, isValidElement, useState } from 'react';
import { RefCallBack } from 'react-hook-form';

export default function DatePicker({
  inputProps,
  datePickerProps
}: {
  inputProps?: { ref?: RefCallBack } & Omit<TextFieldProps, 'inputRef'>;
  datePickerProps?: Omit<DatePickerProps<Date, Date>, 'renderInput'>;
}) {
  const [value, setValue] = useState<Date | null>(new Date());
  return (
    <>
      <MuiDatePicker
        openTo="year"
        views={['year', 'month', 'day']}
        inputFormat="dd/MM/yyyy"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={({ InputProps, inputRef, ...params }) => {
          const iRef = inputRef as any;
          return (
            <TextField
              sx={{ maxWidth: 175 }}
              InputProps={{
                ...InputProps,
                endAdornment: (
                  <>
                    <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} />
                    {Children.map(InputProps?.endAdornment, (child) => {
                      if (isValidElement(child)) {
                        return {
                          ...child,
                          props: {
                            ...child.props,
                            sx: {
                              ...child.props.sx,
                              ml: 0
                            }
                          }
                        };
                      }
                      return child;
                    })}
                  </>
                )
              }}
              variant="outlined"
              size="small"
              inputRef={(e) => {
                if (inputProps?.ref) {
                  inputProps?.ref(e);
                }
                if (iRef) {
                  iRef(e);
                }
              }}
              {...params}
              {...inputProps}
            />
          );
        }}
        {...datePickerProps}
      />
    </>
  );
}
