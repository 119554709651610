import { object, string, enum as zodEnum, infer as Infer } from 'zod';

export const OwnershipSchema = object({
  _id: string(),
  name: string(),
  createdAt: string(),
  updatedAt: string()
});

export type OwnershipSchemaType = Infer<typeof OwnershipSchema>;
export type OwnershipReturnType = {
  OwnershipsList: OwnershipSchemaType[];
  totalCount: {
    count: number;
  };
};

export const OwnershipFormSchema = OwnershipSchema.omit({ createdAt: true, _id: true, updatedAt: true });
export const OwnershipCreateSchema = OwnershipFormSchema;

export type OwnershipFormType = Infer<typeof OwnershipFormSchema>;
export type OwnershipCreateType = Infer<typeof OwnershipCreateSchema>;
