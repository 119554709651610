import React, { ReactNode, useMemo } from 'react';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TablePagination, { TablePaginationProps } from '@mui/material/TablePagination';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Skeleton from '@mui/material/Skeleton';
import { useWindowSize } from 'react-use';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Table({
  tableHeadCells,
  loading,
  tablePagination,
  children
}: {
  tableHeadCells: TableCellProps[];
  loading: boolean;
  tablePagination: TablePaginationProps;
  children: ReactNode;
}) {
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = useMemo(
    () => (tablePagination.page > 0 ? Math.max(0, (1 + tablePagination.page) * tablePagination.rowsPerPage - tablePagination.count) : 0),
    [tablePagination]
  );
  const { width } = useWindowSize();

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }} elevation={0}>
          <TableContainer sx={{ maxWidth: width > 650 ? width - 308 : 308 }}>
            <MuiTable aria-label="dashboard table">
              <TableHead>
                <TableRow>
                  {tableHeadCells.map(({ children: cellChildren, ...cellProps }, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        borderBottom: 1,
                        borderTop: 1,
                        borderColor: (theme) => theme.palette.divider
                      }}
                      {...cellProps}>
                      <Typography sx={{ fontWeight: 700, color: 'text.secondary' }}>{cellChildren}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  [...new Array(tablePagination.rowsPerPage)].map((_row, index) => (
                    <TableRow key={index}>
                      <TableCell colSpan={tableHeadCells.length} sx={{ py: 0 }}>
                        <Skeleton animation="wave" height={100} />
                      </TableCell>
                    </TableRow>
                  ))}
                {!loading && (
                  <>
                    {children}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 100 * emptyRows }}>
                        <TableCell colSpan={tableHeadCells.length} height="100%" />
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </MuiTable>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page'
              },
              native: true
            }}
            ActionsComponent={TablePaginationActions}
            sx={{
              border: 0,
              '& .MuiTablePagination-displayedRows': {
                mb: 0
              },
              '& 	.MuiTablePagination-selectLabel': {
                mb: 0
              }
            }}
            {...tablePagination}
          />
        </Paper>
      </Box>
    </>
  );
}

export default Table;
