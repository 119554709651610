import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './Authenticate.module.scss';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import logo from '~/assets/images/OSL_logo.png';
import { useWindowSize } from 'react-use';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LogInFormSchema, LogInFormType } from '~/models/User';
import { login, getCurrenSession } from '~/api/user';

export function Authenticate() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { width, height } = useWindowSize();
  const [showPassword, setShowPassword] = useState(false);
  const [responseError, setResponseError] = useState('');
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<LogInFormType>({
    mode: 'onChange',
    resolver: zodResolver(LogInFormSchema),
    defaultValues: {
      username: '',
      password: ''
    }
  });

  const onSubmit: SubmitHandler<LogInFormType> = async (data) => {
    setLoading(true);
    const loginResult = await login(data.username, data.password);
    if (typeof loginResult !== 'number' && loginResult?.error) {
      setResponseError(loginResult?.error);
    }
    if (loginResult === 200) {
      navigate('/dashboard/requisition');
    }
    setLoading(false);
  };

  useEffect(() => {
    const checkCurrentSession = async () => {
      const sessionResult = await getCurrenSession();
      if (sessionResult.status === 200) {
        navigate('/dashboard/requisition');
      }
    };
    checkCurrentSession();
  }, []);

  return (
    <Grid container spacing={2} className={clsx('container', styles.main_container)}>
      <Grid item xs={12} lg={4} className="d-flex align-items-center justify-content-center">
        <Stack spacing={2} direction="column" justifyContent="center" style={{ width: '350px' }}>
          <div className="d-flex justify-content-center">
            <img src={logo} alt="logo" width="100" height="auto" />
          </div>
          <Typography variant="h5" gutterBottom component="div" align="center">
            Login to <strong>OSL</strong>
          </Typography>
          <FormControl component="form" onSubmit={handleSubmit(onSubmit)} id="login-form">
            <Stack spacing={2} direction="column" justifyContent="center">
              <Controller
                name="username"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    id="username"
                    label="Username"
                    variant="outlined"
                    error={!!errors.username}
                    inputRef={ref}
                    helperText={errors.username?.message}
                    {...field}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    id="password"
                    label="Password"
                    variant="outlined"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    inputRef={ref}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    {...field}
                  />
                )}
              />
              {responseError && <Typography color="primary">{responseError}</Typography>}
              <Button size="large" disabled={loading} type="submit" form="login-form" variant="contained" className="w-100">
                Login
              </Button>
              <Typography variant="body1" gutterBottom component="div" align="right">
                Forgot password?
              </Typography>
            </Stack>
          </FormControl>
        </Stack>
      </Grid>
      {width >= 1200 && <Grid item xs={12} lg={8} className={clsx('background', styles.auth_background)}></Grid>}
    </Grid>
  );
}
