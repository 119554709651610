import { useState } from 'react';
import { EquipmentTableReturnType } from '~/models/Equipment';
import { getAllEquipments } from '~/api/equipment';

export function useEquipments(page: number, perPage: number) {
  const [pageN, setPageN] = useState(page);
  const [pageSize, setPageSize] = useState(perPage);
  const [loading, setLoading] = useState(false);
  const [equipmentData, setEquipmentData] = useState<EquipmentTableReturnType>({ equipmentList: [], totalCount: 0 });

  const getEquipments = async () => {
    setLoading(true);
    const result = await getAllEquipments(pageN, pageSize);
    setEquipmentData(result);
    setLoading(false);
  };

  const getNextPage = async () => {
    const total = equipmentData?.totalCount;
    if (total && total > (pageN + 1) * pageSize) {
      setLoading(true);
      const result = await getAllEquipments(pageN + 1, pageSize);
      setPageN((pagen) => pagen + 1);
      setEquipmentData((list) => ({ ...list, equipmentData: [...list.equipmentList, ...result.equipmentData] }));
      setLoading(false);
    }
  };

  return {
    equipmentData,
    loading,
    getEquipments,
    getNextPage
  };
}
