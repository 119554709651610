import Grid, { GridProps } from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { HTMLAttributes, ReactNode, useContext, useMemo } from 'react';

type FieldConfig = {
  formItem?: Omit<GridProps, 'container'>;
  label?: Omit<GridProps, 'item'>;
  inputField?: Omit<GridProps, 'item'>;
};

const initialState: FieldConfig = {
  formItem: {
    columnSpacing: { md: 5 },
    rowSpacing: { xs: 2, md: 0 }
  },
  label: { xs: 12, md: 2 },
  inputField: { xs: 12, md: 10 }
};

const FormContext = React.createContext<FieldConfig>(initialState);

function FormField({ label, children }: { label?: ReactNode; children?: ReactNode }) {
  const config = useContext(FormContext);

  return (
    <Grid item xs={12}>
      <Grid container display="flex" justifyContent="space-between" {...config.formItem}>
        <Grid item {...config.label}>
          <Typography component="div" fontSize={12} fontWeight={500}>
            {label}
          </Typography>
        </Grid>
        <Grid item {...config.inputField}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}

function Form({
  children,
  fieldConfig,
  rowSpacing,
  ...props
}: {
  children?: ReactNode;
  fieldConfig?: FieldConfig;
} & HTMLAttributes<HTMLFormElement> &
  Omit<GridProps<'form', {}>, 'container' | 'children'>) {
  const config = useMemo(
    () => ({
      formItem: { ...initialState.formItem, ...fieldConfig?.formItem },
      label: { ...initialState.label, ...fieldConfig?.label },
      inputField: {
        ...initialState.inputField,
        ...fieldConfig?.inputField
      }
    }),
    []
  );

  return (
    <FormContext.Provider value={config}>
      <Grid container component="form" rowSpacing={rowSpacing ?? 2.5} {...props}>
        {children}
      </Grid>
    </FormContext.Provider>
  );
}

export default Object.assign(Form, { FormField });
