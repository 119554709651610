import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import OSLModal from '~/components/Modal';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { RequisitionCreateSchema, RequisitionFormType, RequisitionUpdateType } from '~/models/Requisition';
import FormControlLabel from '@mui/material/FormControlLabel';
import OSLForm from '~/components/Form';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material';
import OSLTimePicker from '~/components/TimePicker';
import OSLHourInput from '~/components/HourInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import AutoComplete from '@mui/material/Autocomplete';
import { useEquipments } from '~/presentation/Equipment/hooks/useEquipments';
import { useMechanics } from '../hooks/useMechanics';
import { useVessels } from '../hooks/useVessels';
import { usePrincipals } from '../hooks/usePrincipals';
import CircularProgress from '@mui/material/CircularProgress';
import { addRequisition, updateRequisition } from '~/api/requisitions';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { setPage, getAllRequisitions } from '../redux/requisition.actions';
import OwnershipForm from '~/forms/OwnershipForm';
import EquipmentForm from '~/forms/EquipmentForm';
import VesselForm from '~/forms/VesselForm';
import PrincipalForm from '~/forms/PrincipalForm';
import { getAllOwnerships } from '~/api/ownership';
import { OwnershipSchemaType } from '~/models/Ownership';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { REQUISITION_STATUS } from '~/config/const';

export default function RequisitionForm({
  isOpen,
  handleClose,
  mode,
  data
}: {
  isOpen: boolean;
  handleClose: () => void;
} & ({ mode: 'edit'; data: RequisitionUpdateType } | { mode: 'create'; data?: never })) {
  const dispatch = useAppDispatch();
  const requisitionState = useAppSelector((state) => state.requisition);
  const { page, perPage } = requisitionState;
  const mobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { loading: equipmentLoading, getEquipments, equipmentData } = useEquipments(0, 50);
  const { loading: mechanicLoading, getMechanics, mechanicData } = useMechanics(0, 50);
  const { loading: vesselLoading, getVessels, vesselData } = useVessels(0, 50);
  const { loading: principalLoading, getPrincipals, principalData } = usePrincipals(0, 50);
  const [showOwnershipForm, setShowOwnershipForm] = useState<boolean>(false);
  const [needLoadOwnership, setNeedLoadOwnership] = useState<boolean>(true);
  const [ownershipList, setOwnershipList] = useState<OwnershipSchemaType[]>([]);
  const [ownershipLoading, setOwnershipLoading] = useState<boolean>(false);
  const [showEquipmentForm, setShowEquipmentForm] = useState<boolean>(false);
  const [showVesselForm, setShowVesselForm] = useState<boolean>(false);
  const [showPrincipalForm, setShowPrincipalForm] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<RequisitionFormType>({
    mode: 'onChange',
    resolver: zodResolver(RequisitionCreateSchema),
    defaultValues:
      mode === 'edit'
        ? {
            ...data,
            equipment: data?.equipment,
            givenBy: data?.givenBy,
            receivedBy: data?.receivedBy,
            operator: data?.operator,
            vessel: data?.vessel,
            principal: data?.principal
          }
        : {
            equipment: {},
            ac: 'OSL',
            cargo: 'IMPORT',
            reportingTime: '07:00 PM',
            status: 'NOT STARTED'
          }
  });

  const onSubmit: SubmitHandler<RequisitionFormType> = async (data) => {
    const formData = {
      equipmentId: data?.equipment?._id,
      ac: data?.ac,
      givenBy: data?.givenBy,
      givenAt: data?.givenAt,
      receivedBy: data?.receivedBy,
      receivedAt: data?.receivedAt,
      vesselId: data?.vessel?._id,
      principalId: data?.principal?._id,
      cargo: data?.cargo,
      marchingTime: data?.marchingTime,
      operator: data?.operator,
      reportingTime: data?.reportingTime,
      status: data?.status
    };
    let result;

    if (mode === 'create') {
      result = await addRequisition(formData);
    }

    if (mode === 'edit') {
      result = await updateRequisition({ ...formData, requisitionId: data?.requisitionId || '' });
    }

    if (result === 'success') {
      if (page === 0) {
        dispatch(getAllRequisitions(page, perPage));
      } else {
        dispatch(setPage(0));
      }
      handleClose();
      return;
    }
    console.error('Something went wrong');
  };

  const handleShowEquipmentForm = () => {
    setShowEquipmentForm(true);
  };

  const handleShowVesselForm = () => {
    setShowVesselForm(true);
  };

  const handleShowPrincipalForm = () => {
    setShowPrincipalForm(true);
  };

  const handleShowOwnershipForm = () => {
    setShowOwnershipForm(true);
  };

  useEffect(() => {
    if (needLoadOwnership) {
      setOwnershipLoading(true);
      getAllOwnerships()
        .then((res) => {
          setOwnershipList(res.ownershipList);
          setNeedLoadOwnership(false);
          setOwnershipLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setNeedLoadOwnership(false);
          setOwnershipLoading(false);
        });
    }
  }, [needLoadOwnership]);

  return (
    <>
      <OSLModal onClose={handleClose} open={isOpen} modalWidth={800}>
        <OSLModal.Header onClose={handleClose}>{mode === 'create' ? 'Add New Requisition' : 'Edit Requisition'}</OSLModal.Header>
        <Box px={5} py={4}>
          <OSLForm
            onSubmit={handleSubmit(onSubmit)}
            id="requisition-form"
            fieldConfig={{ label: { md: 3 }, inputField: { md: 8 }, formItem: { columnSpacing: { md: 10.5 } } }}>
            <OSLForm.FormField
              label={
                <>
                  Equipment <br /> (CAT/ZBAR/JCB/EXA/HYDRA/FRK LFT)
                </>
              }>
              <Stack direction="row" alignItems="center">
                <Controller
                  name="equipment"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <AutoComplete
                      id="equipment"
                      fullWidth
                      onOpen={() => {
                        getEquipments();
                      }}
                      loading={equipmentLoading}
                      options={equipmentData.equipmentList || []}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option._id === value._id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {equipmentLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                          error={!!errors.equipment}
                          helperText={errors.equipment?.message}
                          placeholder="Enter Code/name"
                        />
                      )}
                      onChange={(_, data) => {
                        onChange(data);
                        return data;
                      }}
                      defaultValue={data?.equipment}
                    />
                  )}
                />
                <IconButton className="ml-2" color="primary" aria-label="add equipment" onClick={handleShowEquipmentForm}>
                  <AddIcon />
                </IconButton>
              </Stack>
            </OSLForm.FormField>

            <OSLForm.FormField
              label={
                <>
                  A/c of <br /> (OSL/MM/HIRE)
                </>
              }>
              {ownershipLoading ? (
                <CircularProgress />
              ) : (
                <Stack direction="row" alignItems="center">
                  <Controller
                    name="ac"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => {
                      return (
                        <FormControl component="fieldset" error={!!errors.ac}>
                          <RadioGroup
                            row
                            aria-labelledby="ac"
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            {...field}>
                            {ownershipList.map((o, i) => (
                              <FormControlLabel
                                key={o._id}
                                value={o.name}
                                control={<Radio size="small" />}
                                label={<Typography variant="body2">{o.name}</Typography>}
                              />
                            ))}
                          </RadioGroup>
                          <FormHelperText>{errors.ac?.message}</FormHelperText>
                        </FormControl>
                      );
                    }}
                  />
                  <IconButton className="ml-2" color="primary" aria-label="add ownership" onClick={handleShowOwnershipForm}>
                    <AddIcon />
                  </IconButton>
                </Stack>
              )}
            </OSLForm.FormField>

            <OSLForm.FormField label="Requisition given by/Time">
              <Grid container component="div" columnSpacing={2} rowSpacing={mobileScreen ? 1 : 0}>
                <Grid item xs={12} md={7}>
                  <Controller
                    name="givenBy"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        variant="outlined"
                        error={!!errors.givenBy}
                        helperText={errors.givenBy?.message}
                        fullWidth
                        inputRef={ref}
                        size="small"
                        placeholder="Enter Requisition given by"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Controller
                    name="givenAt"
                    control={control}
                    render={({ field: { value } }) => (
                      <OSLTimePicker
                        onTimeChange={(value: string) => setValue('givenAt', value)}
                        error={!!errors.givenAt}
                        helperText={errors.givenAt?.message}
                        value={value}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </OSLForm.FormField>

            <OSLForm.FormField label="Requisition received by/Time">
              <Grid container component="div" columnSpacing={2} rowSpacing={mobileScreen ? 1 : 0}>
                <Grid item xs={12} md={7}>
                  <Controller
                    name="receivedBy"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        variant="outlined"
                        error={!!errors.receivedBy}
                        helperText={errors.receivedBy?.message}
                        fullWidth
                        inputRef={ref}
                        size="small"
                        placeholder="Enter Requisition received by"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Controller
                    name="receivedAt"
                    control={control}
                    render={({ field: { value } }) => (
                      <OSLTimePicker
                        onTimeChange={(value: string) => setValue('receivedAt', value)}
                        error={!!errors.receivedAt}
                        helperText={errors.receivedAt?.message}
                        value={value}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </OSLForm.FormField>

            <OSLForm.FormField label="Name of the Vessel">
              <Stack direction="row" alignItems="center">
                <Controller
                  name="vessel"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <AutoComplete
                      id="vessel"
                      fullWidth
                      onOpen={() => {
                        getVessels();
                      }}
                      loading={vesselLoading}
                      options={vesselData.vesselsList || []}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option._id === value._id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {vesselLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                          error={!!errors.vessel}
                          helperText={errors.vessel?.message}
                          placeholder="Enter Code/name"
                        />
                      )}
                      onChange={(_, data) => {
                        onChange(data);
                        return data;
                      }}
                      defaultValue={data?.vessel}
                    />
                  )}
                />
                <IconButton className="ml-2" color="primary" aria-label="add vessel" onClick={handleShowVesselForm}>
                  <AddIcon />
                </IconButton>
              </Stack>
            </OSLForm.FormField>

            <OSLForm.FormField label="Name of the Principal">
              <Stack direction="row" alignItems="center">
                <Controller
                  name="principal"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <AutoComplete
                      id="principal"
                      fullWidth
                      onOpen={() => {
                        getPrincipals();
                      }}
                      loading={principalLoading}
                      options={principalData.principalsList || []}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option._id === value._id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {principalLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                          error={!!errors.principal}
                          helperText={errors.principal?.message}
                          placeholder="Enter Code/name"
                        />
                      )}
                      onChange={(_, data) => {
                        onChange(data);
                        return data;
                      }}
                      defaultValue={data?.principal}
                    />
                  )}
                />
                <IconButton className="ml-2" color="primary" aria-label="add principal" onClick={handleShowPrincipalForm}>
                  <AddIcon />
                </IconButton>
              </Stack>
            </OSLForm.FormField>

            <OSLForm.FormField
              label={
                <>
                  Cargo <br /> (Import/Export)
                </>
              }>
              <Controller
                name="cargo"
                control={control}
                render={({ field: { ref, ...field } }) => {
                  const options = ['IMPORT', 'EXPORT'];
                  return (
                    <FormControl component="fieldset" error={!!errors.cargo}>
                      <RadioGroup row aria-labelledby="cargo" {...field}>
                        {options.map((option: string, i: number) => (
                          <FormControlLabel
                            key={i}
                            value={option}
                            control={<Radio size="small" />}
                            label={<Typography variant="body2">{option}</Typography>}
                          />
                        ))}
                      </RadioGroup>
                      <FormHelperText>{errors.cargo?.message}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
            </OSLForm.FormField>

            <OSLForm.FormField label="Equipment Marching Time">
              <Controller
                name="marchingTime"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OSLHourInput error={!!errors.marchingTime} helperText={errors.marchingTime?.message} inputRef={ref} {...field} />
                )}
              />
            </OSLForm.FormField>

            <OSLForm.FormField label="Name of the Operator">
              <Controller
                name="operator"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="outlined"
                    error={!!errors.operator}
                    helperText={errors.operator?.message}
                    fullWidth
                    inputRef={ref}
                    size="small"
                    placeholder="Enter Requisition operator"
                    {...field}
                  />
                )}
              />
            </OSLForm.FormField>

            <OSLForm.FormField label="Reporting time at the place of work">
              <Controller
                name="reportingTime"
                control={control}
                render={({ field: { value } }) => (
                  <OSLTimePicker
                    onTimeChange={(value: string) => setValue('reportingTime', value)}
                    error={!!errors.reportingTime}
                    helperText={errors.reportingTime?.message}
                    value={value}
                  />
                )}
              />
            </OSLForm.FormField>

            <OSLForm.FormField label="Status">
              <Controller
                name="status"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => {
                  return (
                    <FormControl component="fieldset" error={!!errors.status}>
                      <RadioGroup
                        row
                        aria-labelledby="status"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        {...field}>
                        {REQUISITION_STATUS.map((o, i) => (
                          <FormControlLabel
                            key={o + i}
                            value={o}
                            control={<Radio size="small" />}
                            label={<Typography variant="body2">{o}</Typography>}
                          />
                        ))}
                      </RadioGroup>
                      <FormHelperText>{errors.status?.message}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
            </OSLForm.FormField>
          </OSLForm>
        </Box>

        <OSLModal.Footer justifyContent={mobileScreen ? 'start' : 'right'}>
          <Stack direction={mobileScreen ? 'column' : 'row'} gap={4} width={mobileScreen ? '100%' : 'auto'}>
            <Button
              aria-label="cancel"
              onClick={handleClose}
              variant="outlined"
              color="primary"
              className="text-capitalize text-nowrap"
              sx={(theme) => ({
                width: 134,
                height: 50,
                borderRadius: 2,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              })}>
              <Typography>Cancel</Typography>
            </Button>
            <Button
              aria-label="save"
              variant="contained"
              color="primary"
              className="text-capitalize text-nowrap"
              disableElevation
              sx={(theme) => ({
                width: 134,
                height: 50,
                borderRadius: 2,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              })}
              type="submit"
              form="requisition-form">
              <Typography>Save</Typography>
            </Button>
          </Stack>
        </OSLModal.Footer>
      </OSLModal>

      <EquipmentForm //
        isOpen={showEquipmentForm}
        handleClose={() => setShowEquipmentForm(false)}
        mode="create"
      />
      <PrincipalForm //
        isOpen={showPrincipalForm}
        handleClose={() => setShowPrincipalForm(false)}
        mode="create"
      />
      <VesselForm //
        isOpen={showVesselForm}
        handleClose={() => setShowVesselForm(false)}
        mode="create"
      />
      <OwnershipForm //
        setNeedReload={setNeedLoadOwnership}
        isOpen={showOwnershipForm}
        handleClose={() => setShowOwnershipForm(false)}
        mode="create"
      />
    </>
  );
}
