import { useState } from 'react';
import { VesselReturnType } from '~/models/Vessel';
import { getAllVessels } from '~/api/vessels';

export function useVessels(page: number, perPage: number) {
  const [pageN, setPageN] = useState(page);
  const [pageSize, setPageSize] = useState(perPage);
  const [loading, setLoading] = useState(false);
  const [vesselData, setVesselData] = useState<VesselReturnType>({ vesselsList: [], totalCount: { count: 0 } });

  const getVessels = async () => {
    setLoading(true);
    const result = await getAllVessels(pageN, pageSize);
    setVesselData(result);
    setLoading(false);
  };

  const getNextPage = async () => {
    const total = vesselData?.totalCount?.count;
    if (total && total > (pageN + 1) * pageSize) {
      setLoading(true);
      const result = await getAllVessels(pageN + 1, pageSize);
      setPageN((pagen) => pagen + 1);
      setVesselData((list) => ({ ...list, vesselData: [...list.vesselsList, ...result.vesselData] }));
      setLoading(false);
    }
  };

  return {
    vesselData,
    loading,
    getVessels,
    getNextPage
  };
}
