import React, { useEffect } from 'react';
import type { TableCellProps } from '@mui/material/TableCell';
import MaintenanceListTableRow from './MaintenanceListTableRow';
import { useAppSelector, useAppDispatch } from '~/redux/hooks';
import { getAllMaintenanceReqs } from '../../redux/maintenance.actions';
import OSLTable from '~/components/Table';

const headCells: TableCellProps[] = [
  {
    align: 'left',
    children: 'Maintenance Req. No.'
  },
  {
    align: 'left',
    children: 'Service Req. No.'
  },
  {
    align: 'left',
    children: 'Details'
  },
  {
    align: 'left',
    children: 'Type'
  },
  {
    align: 'center',
    children: 'Status'
  },
  {
    align: 'center',
    children: 'Action'
  }
];

export default function MaintenanceListTable() {
  const maintenanceState = useAppSelector((state) => state.maintenance);
  const { loading, maintenanceReqList, searchValue, searchKeys } = maintenanceState;
  const dispatch = useAppDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const getSearchParams = () => {
      let searchParams: Record<string, string> = {};
      if (searchValue && searchKeys) {
        if (typeof searchKeys === 'string') {
          searchParams[searchKeys] = searchValue;
          return searchParams;
        }
        searchKeys.forEach((k) => (searchParams[k] = searchValue));
        return searchParams;
      }
    };

    dispatch(getAllMaintenanceReqs(page, rowsPerPage, getSearchParams()));
  }, [page, rowsPerPage, searchKeys, searchValue, dispatch]);

  return (
    <>
      <OSLTable
        tableHeadCells={headCells}
        loading={loading}
        tablePagination={{
          count: maintenanceReqList?.totalCount?.count || 0,
          rowsPerPage,
          page,
          onPageChange: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage
        }}>
        {maintenanceReqList?.maintenancesList?.map((maintenanceReq) => (
          <MaintenanceListTableRow key={maintenanceReq?._id} maintenanceReq={maintenanceReq} />
        ))}
      </OSLTable>
    </>
  );
}
