import { LubricantTableReturnType } from '../../../models/Lubricant';
import {
  GET_LUBRICANTS_FAILURE,
  GET_LUBRICANTS_REQUEST,
  GET_LUBRICANTS_SUCCESS,
  SET_LUBRICANT_PAGE,
  SET_LUBRICANT_PER_PAGE
} from './lubricant.constants';

interface LubricantState {
  lubricantList: LubricantTableReturnType;
  loading: boolean;
  page: number;
  perPage: number;
}

const initialState: LubricantState = {
  lubricantList: { lubricantList: [], totalCount: { count: 0 } },
  loading: false,
  page: 0,
  perPage: 5
};

const lubricantReducer = (state = initialState, action: { response?: any; type?: any }): LubricantState => {
  const { type } = action;

  switch (type) {
    case GET_LUBRICANTS_REQUEST:
      return { ...state, loading: true };
    case GET_LUBRICANTS_SUCCESS: {
      return {
        ...state,
        lubricantList: action.response,
        loading: false
      };
    }
    case GET_LUBRICANTS_FAILURE:
      return { ...state, loading: false };

    case SET_LUBRICANT_PAGE:
      return { ...state, page: action.response };

    case SET_LUBRICANT_PER_PAGE:
      return { ...state, perPage: action.response };

    default:
      return state;
  }
};

export default lubricantReducer;
