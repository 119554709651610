import { object, string, infer as Infer, array } from 'zod';

export const timeValidation = string().regex(/^(1[0-2]|0?[1-9]):([0-5]?[0-9]) [AP]M$/, { message: 'Please enter a valid time' });

export const VesselSchema = object({
  _id: string(),
  name: string(),
  createdAt: timeValidation,
  updatedAt: timeValidation
});

export type VesselSchemaType = Infer<typeof VesselSchema>;

export type VesselReturnType = {
  vesselsList: VesselSchemaType[];
  totalCount: {
    count: number;
  };
};

export const VesselFormSchema = VesselSchema.omit({ createdAt: true, _id: true, updatedAt: true });
export const VesselCreateSchema = VesselFormSchema;

export type VesselFormType = Infer<typeof VesselFormSchema>;
export type VesselCreateType = Infer<typeof VesselCreateSchema>;