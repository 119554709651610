import React, { Suspense, useState } from 'react';
import MainScreen from '../../MainScreen/View/MainScreen';
import styles from './Lubricant.module.scss';

import LubricantListTable from '../Components/LubricantListTable';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import SelectButton from '~/components/SelectButton';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';
import Search from '~/components/Search';

const LubricantForm = React.lazy(() => import('../Components/LubricantForm'));
const LubricantStockInForm = React.lazy(() => import('../Components/LubricantStockInForm'));
const LubricantStockOutForm = React.lazy(() => import('../Components/LubricantStockOutForm'));

const updateOptions = [
  { label: 'Stock In', value: 'StockIn' },
  { label: 'Stock Out', value: 'StockOut' }
];

export function Lubricant() {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showStockOutForm, setShowStockOutForm] = useState(false);
  const [showStockInForm, setShowStockInForm] = useState(false);

  const handleOpenForm = (type: string) => {
    switch (type) {
      case 'StockIn':
        setShowStockInForm(true);
        return;
      default:
        setShowStockOutForm(true);
    }
  };

  return (
    <>
      <MainScreen title="Inventory - Lubricants">
        <div>
          <Box component="div" display="flex" flexDirection="column">
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  alignItems: 'start',
                  gap: 3,
                  px: 0,
                  mx: 3
                },
                flexDirection: 'row',
                alignItems: 'center',
                gap: 0,
                p: 3.75
              })}>
              <Box width={{ xs: '100%', sm: '100%', md: 'auto' }}>
                <Search />
              </Box>
              <Box
                display="flex"
                sx={(theme) => ({
                  [theme.breakpoints.down('md')]: { gap: 2, flexDirection: 'column-reverse' },
                  gap: 5,
                  flexDirection: 'row'
                })}>
                <Stack direction="row" gap={1}>
                  <SelectButton
                    variant="outlined"
                    color="primary"
                    label={<span className="text-capitalize">Stock Update</span>}
                    sx={{ borderRadius: 2, height: 50, width: 'fit-content' }}>
                    {updateOptions.map((option, index) => (
                      <MenuItem key={index} onClick={() => handleOpenForm(option.value)}>
                        <Typography variant="body2">{option.label}</Typography>
                      </MenuItem>
                    ))}
                  </SelectButton>
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-capitalize"
                    sx={{
                      width: 'fit-content',
                      height: 50,
                      borderRadius: 2
                    }}
                    startIcon={<AddIcon fontSize="small" />}
                    disableElevation
                    onClick={() => setShowCreateForm(true)}>
                    Add New
                  </Button>
                </Stack>
              </Box>
            </Box>
            <LubricantListTable />
          </Box>
        </div>
      </MainScreen>
      <Suspense>
        {showCreateForm && <LubricantForm mode="create" isOpen handleClose={() => setShowCreateForm(false)} />}

        {showStockInForm && <LubricantStockInForm mode="create" isOpen handleClose={() => setShowStockInForm(false)} />}

        {showStockOutForm && <LubricantStockOutForm mode="create" isOpen handleClose={() => setShowStockOutForm(false)} />}
      </Suspense>
    </>
  );
}
