import { Action } from 'redux';
import { persistCombineReducers, persistReducer } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import Config from '../../config/default';
import userReducer from '../../presentation/Authenticate/redux/user.reducer';
import requisitionReducer from '../../presentation/Requisition/redux/requisition.reducer';
import equipmentReducer from '../../presentation/Equipment/redux/equipment.reducer';
import lubricantReducer from '../../presentation/Lubricant/redux/lubricant.reducer';
import sparePartReducer from '../../presentation/SparePart/redux/sparePart.reducer';
import maintenanceReducer from '../../presentation/Maintenance/redux/maintenance.reducer';
import storage from 'redux-persist/lib/storage';

const config = {
  key: Config.appStorageKey,
  storage: storage
  // blacklist: ['user']
  // whitelist: ['auth']
};

export const rootReducer = persistCombineReducers(config, {
  // combine all reducer here
  user: userReducer,
  requisition: requisitionReducer,
  equipment: equipmentReducer,
  lubricant: lubricantReducer,
  sparePart: sparePartReducer,
  maintenance: maintenanceReducer
});

// export const rootReducer = (state: any, action: Action<any>) => {
//     // Do global operations
//     return appReducer(state, action);
// }

export type IRootState = ReturnType<typeof rootReducer>;
