export const envTypes = {
  development: 'development' // Can be production
};

export const primaryColor = '#fff';
export const secondaryColor = '#000';

export const LOGO_PATH = '~/assets/images/OSL_logo.png';

export const ASSOCIATION_OPTIONS = [
  { label: 'OSL', value: 'osl' },
  { label: 'MM', value: 'mm' },
  { label: 'HIRE', value: 'hire' }
];

export const REQUISITION_STATUS = ['NOT STARTED', 'ON GOING', 'COMPLETED', 'CANCELED', 'BLOCKED'];

export const getRequisitionStatusColor = (status: string) => {
  switch (status) {
    case 'NOT STARTED':
      return 'primary';
    case 'ON GOING':
      return 'warning';
    case 'COMPLETED':
      return 'success';
    case 'CANCELED':
      return 'primary';
    default:
      return 'secondary'
  }
};
