import { Children, isValidElement, ReactNode, useMemo, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import MuiModal, { ModalProps } from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

const Header = ({ children, onClose }: { children?: ReactNode; onClose?: () => void }) => {
  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" px={4} pt={4} pb={3}>
        <Typography id="transition-modal-title" component="h2" fontWeight={600}>
          {children}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ color: (theme) => theme.palette.text.primary }} />
        </IconButton>
      </Box>
      <Divider component="div" />
    </>
  );
};

const Footer = ({ children, ...props }: BoxProps) => {
  return (
    <>
      <Divider component="div" />

      <Box display="flex" flexDirection="row" justifyContent="right" alignItems="center" px={5} py={4} {...props}>
        {children}
      </Box>
    </>
  );
};

const ModalRoot = ({
  open,
  onClose,
  modalWidth = 626,
  children,
  ...props
}: {
  children?: ReactNode;
  onClose: () => void;
  open: boolean;
  modalWidth?: number | string;
} & Omit<ModalProps, 'children'>) => {
  const renderChildren = useMemo(() => {
    let head: ReactNode;
    let foot: ReactNode;
    const body: ReactNode[] = [];
    Children.forEach(children, (child) => {
      const isFunction = (data: any): data is (...args: any[]) => any => typeof data === 'function';
      if (isValidElement(child) && isFunction(child.type)) {
        if (child.type.name === Header.name) {
          head = child;
          return;
        }
        if (child.type.name === Footer.name) {
          foot = child;
          return;
        }
      }
      return body?.push(child);
    });
    return (
      <>
        {head} <Box sx={{ overflowY: 'scroll' }}>{body}</Box> {foot}
      </>
    );
  }, [children]);

  return (
    <>
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={!!open}
        onClose={onClose}
        closeAfterTransition
        sx={{
          display: 'grid'
        }}
        {...props}>
        <Fade in={open}>
          <Box
            sx={(theme) => ({
              width: modalWidth,
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: 2,
              alignSelf: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifySelf: 'center',
              maxHeight: '100vh',
              [theme.breakpoints.down('md')]: {
                width: '100%',
                maxWidth: 686
              }
            })}>
            {renderChildren}
          </Box>
        </Fade>
      </MuiModal>
    </>
  );
};

export default Object.assign(ModalRoot, { Header, Footer });
