import { boolean, object, string, infer as Infer, date } from 'zod';

// --------------------------------
// START: SCHEMA
// --------------------------------
export const EquipmentSchema = object({
  _id: string(),
  code: string().min(1),
  name: string().min(1),
  avatar: string().url()
});

export const VehicleSchema = object({
  _id: string(),
  equipment: object({
    _id: string(),
    code: string(),
    name: string()
  }),
  number: string().min(1),
  ownership: string(),
  insuranceExpDate: date({
    required_error: 'Please select a date',
    invalid_type_error: 'Not a valid date'
  }),
  pollutionExpDate: date({
    required_error: 'Please select a date',
    invalid_type_error: 'Not a valid date'
  }),
  availability: boolean()
});
export const EquipmentFormSchema = EquipmentSchema.omit({ avatar: true, _id: true, code: true });
export const EquipmentCreateSchema = EquipmentFormSchema;
export const EquipmentDeleteSchema = object({ equipmentId: string() });
export const VehicleFormSchema = VehicleSchema.omit({ _id: true, availability: true });
export const VehicleDeleteSchema = object({ vehicleId: string() });
export const VehicleCreateSchema = object({
  equipmentId: string(),
  number: string(),
  ownership: string(),
  insuranceExpDate: date(),
  pollutionExpDate: date()
});
export const VehicleUpdateSchema = VehicleCreateSchema.merge(
  object({
    vehicleId: string()
  })
);
// --------------------------------
// END: SCHEMA
// --------------------------------

export type Equipment = Infer<typeof EquipmentSchema>;
export type Vehicle = Infer<typeof VehicleSchema>;

// --------------------------------
// START: TYPE
// --------------------------------
export type EquipmentFormType = Infer<typeof EquipmentFormSchema>;
export type VehicleFormType = Infer<typeof VehicleFormSchema>;
export type VehicleCreateType = Infer<typeof VehicleCreateSchema>;
export type VehicleDeleteType = Infer<typeof VehicleDeleteSchema>;
export type VehicleUpdateType = Infer<typeof VehicleUpdateSchema>;
export type EquipmentCreateType = Infer<typeof EquipmentCreateSchema>;
export type EquipmentDeleteType = Infer<typeof EquipmentDeleteSchema>;
export type EquipmentTableSchemaType = Infer<typeof EquipmentSchema>;
export type EquipmentTableReturnType = {
  equipmentList: EquipmentTableSchemaType[];
  totalCount: number;
};
// --------------------------------
// END: SCHEMA
// --------------------------------
