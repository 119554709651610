import React, { useEffect } from 'react';
import type { TableCellProps } from '@mui/material/TableCell';
import EquipmentListTableRow from './LubricantListTableRow';
import { useAppSelector, useAppDispatch } from '~/redux/hooks';
import { getAllLubricants, setPage, setPerPage } from '../../redux/lubricant.actions';
import OSLTable from '~/components/Table';

const headCells: TableCellProps[] = [
  {
    align: 'left',
    children: 'Code'
  },
  {
    align: 'left',
    children: 'Name'
  },
  {
    align: 'left',
    children: 'Type'
  },
  {
    align: 'center',
    children: 'Stock Qty'
  },
  {
    align: 'center',
    children: 'Grade'
  },
  {
    align: 'center',
    children: 'Last Received Date'
  },
  {
    align: 'center',
    children: 'Last Received Qty'
  },
  {
    align: 'center',
    children: 'Threshold'
  },
  {
    align: 'center',
    children: 'Entry'
  },
  {
    align: 'center',
    children: 'Action'
  }
];

export default function LubricantListTable() {
  const lubricantState = useAppSelector((state) => state.lubricant);
  const { loading, lubricantList, page, perPage } = lubricantState;
  const dispatch = useAppDispatch();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
  };

  useEffect(() => {
    dispatch(getAllLubricants(page, perPage));
  }, [page, perPage]);

  return (
    <>
      <OSLTable
        tableHeadCells={headCells}
        loading={loading}
        tablePagination={{
          count: lubricantList?.totalCount?.count || 0,
          rowsPerPage: perPage,
          page,
          onPageChange: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage
        }}>
        {lubricantList?.lubricantList?.map((lubricant) => (
          <EquipmentListTableRow key={lubricant?._id} lubricant={lubricant} />
        ))}
      </OSLTable>
    </>
  );
}
