import { useState } from 'react';
import { MechanicReturnType } from '~/models/Mechanic';
import { getAllMechanics } from '~/api/mechanics';

export function useMechanics(page: number, perPage: number) {
  const [pageN, setPageN] = useState(page);
  const [pageSize, setPageSize] = useState(perPage);
  const [loading, setLoading] = useState(false);
  const [mechanicData, setEquipmentData] = useState<MechanicReturnType>({ mechanicsList: [], totalCount: { count: 0 } });

  const getMechanics = async () => {
    setLoading(true);
    const result = await getAllMechanics(pageN, pageSize);
    setEquipmentData(result);
    setLoading(false);
  };

  const getNextPage = async () => {
    const total = mechanicData?.totalCount?.count;
    if (total && total > (pageN + 1) * pageSize) {
      setLoading(true);
      const result = await getAllMechanics(pageN + 1, pageSize);
      setPageN((pagen) => pagen + 1);
      setEquipmentData((list) => ({ ...list, mechanicData: [...list.mechanicsList, ...result.mechanicData] }));
      setLoading(false);
    }
  };

  return {
    mechanicData,
    loading,
    getMechanics,
    getNextPage
  };
}
