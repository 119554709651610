import React from 'react';
import * as PATH from './routesConfig';
import { faClipboardList, faToolbox, faBoxesStacked, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';

export const getSidebarMenus = (permissions: string[]) => {
  const sidebar: Record<string, any>[] = [
    {
      path: PATH.DASHBOARD_PATH,
      title: 'Dashboard',
      icon: faMicrosoft
    }
  ];

  permissions.includes('REQUISITION') &&
    sidebar.push(
      {
        path: PATH.REQUISTION_PATH,
        title: 'Requisition',
        icon: faClipboardList
      },
      {
        path: PATH.EQUIPMENT_PATH,
        title: 'Equipment',
        icon: faToolbox
      }
    );

  permissions.includes('INVENTORY') &&
    sidebar.push({
      path: PATH.INVENTORY_PATH,
      title: 'Inventory',
      icon: faBoxesStacked,
      subMenus: [
        {
          path: PATH.INVENTORY_LUBRICANT_PATH,
          title: 'Lubricant'
        },
        {
          path: PATH.INVENTORY_SPARE_PARTS_PATH,
          title: 'Spare parts'
        }
      ]
    });

  permissions.includes('REPORTS') &&
    sidebar.push({
      path: PATH.MAINTENANCE_PATH,
      title: 'Maintenance',
      icon: faScrewdriverWrench
    });

  return sidebar;
};
