import data from './data/log_data.json';
import { axiosPrivate } from './axios';
import { AxiosError, AxiosResponse } from 'axios';
import { RequisitionLogCreateType, RequisitionLogDeleteType, RequisitionLogUpdateType } from '~/models/Requisition';

export const getRequisitionLog = (requisitionId: string) => {
  return new Promise((resolve, reject) => setTimeout(() => resolve(data.slice(0, 5)), 2000)).then((response) => response);
};

export const addRequisitionLog = (requisitionLogData: RequisitionLogCreateType) => {
  return axiosPrivate
    .post('/requisition/log', requisitionLogData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const updateRequisitionLog = (requisitionLogData: RequisitionLogUpdateType) => {
  return axiosPrivate
    .patch('/requisition/log', requisitionLogData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const deleteRequistionLog = (requisitionLogData: RequisitionLogDeleteType) => {
  return axiosPrivate
    .delete('/requisition/log', { data: requisitionLogData })
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};
