import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import OSLModal from '../../../components/Modal';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { RequisitionLogFormSchema, RequisitionLogFormType } from '../../../models/Requisition';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import OSLForm from '../../../components/Form';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material';
import OSLTimePicker from '~/components/TimePicker';
import AutoComplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { usePlaceOfWorks } from '../hooks/usePlaceOfWorks';
import { setPage, getAllRequisitions } from '../redux/requisition.actions';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { addRequisitionLog, updateRequisitionLog } from '~/api/requisitionLog';
import PlaceOfWorkForm from '~/forms/PlaceOfWorkForm';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';

export default function RequisitionLogForm({
  isOpen,
  handleClose,
  mode,
  data,
  requisitionId,
  logId
}: {
  isOpen: boolean;
  handleClose: () => void;
  requisitionId: string;
  logId?: string;
} & ({ mode: 'edit'; data: Record<string, any> } | { mode: 'create'; data?: never })) {
  const mobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const {
    control,
    handleSubmit,
    watch,
    unregister,
    register,
    setValue,
    formState: { errors }
  } = useForm<RequisitionLogFormType>({
    mode: 'onChange',
    resolver: zodResolver(RequisitionLogFormSchema),
    defaultValues:
      mode === 'edit'
        ? {
            startingTime: data?.logData?.startingTime,
            closingTime: data?.logData?.closingTime,
            workDetails: data?.logData?.workDetails,
            remarks: data?.logData?.remarks,
            isIdle: data?.logData?.isIdle,
            breakdownDetails: data?.logData?.breakdownDetails
          }
        : {
            placeOfWork: {},
            startingTime: '',
            closingTime: '',
            workDetails: '',
            remarks: ''
          }
  });
  const dispatch = useAppDispatch();
  const requisitionState = useAppSelector((state) => state.requisition);
  const { page, perPage } = requisitionState;
  const { loading: placeOfWorkLoading, getPlaceOfWorks, placeOfWorkData } = usePlaceOfWorks(0, 50);
  const [showPlaceOfWorkForm, setShowPlaceOfWorkForm] = useState<boolean>(false);

  const isIdle = watch('isIdle');

  useEffect(() => {
    if (!isIdle) {
      unregister('breakdownDetails', { keepDefaultValue: true });
    } else {
      register('breakdownDetails');
    }
  }, [isIdle, unregister, register]);

  const onSubmit: SubmitHandler<RequisitionLogFormType> = async (data) => {
    const formData = {
      requisitionId,
      placeOfWorkId: data?.placeOfWork?._id,
      startingTime: data?.startingTime,
      closingTime: data?.closingTime,
      workDetails: data?.workDetails,
      isIdle: data?.isIdle,
      breakdownDetails: data?.breakdownDetails,
      remarks: data?.remarks
    };
    let result;

    if (mode === 'create') {
      result = await addRequisitionLog(formData);
    }

    if (mode === 'edit') {
      result = await updateRequisitionLog({ ...formData, logId: logId || '' });
    }

    if (result === 'success') {
      if (page === 0) {
        dispatch(getAllRequisitions(page, perPage));
      } else {
        dispatch(setPage(0));
      }
      handleClose();
      return;
    }
    console.error('Something went wrong');
  };

  const handleShowPlaceOfWorkForm = () => {
    setShowPlaceOfWorkForm(true);
  };

  return (
    <>
      <OSLModal onClose={handleClose} open={isOpen} modalWidth={686}>
        <OSLModal.Header onClose={handleClose}>{mode === 'create' ? 'Add Log' : 'Edit Log'}</OSLModal.Header>
        <Box px={5} py={4}>
          <OSLForm
            onSubmit={handleSubmit(onSubmit)}
            id="requisition-log-form"
            fieldConfig={{ label: { md: 2 }, inputField: { md: 9 }, formItem: { columnSpacing: { md: 6 } } }}>
            <OSLForm.FormField label="Place of Work">
              <Stack direction="row" alignItems="center">
                <Controller
                  name="placeOfWork"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <AutoComplete
                      id="placeOfWork"
                      fullWidth
                      onOpen={() => {
                        getPlaceOfWorks();
                      }}
                      loading={placeOfWorkLoading}
                      options={placeOfWorkData.placeOfWorksList || []}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option._id === value._id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {placeOfWorkLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                          error={!!errors.placeOfWork}
                          helperText={errors.placeOfWork?.message}
                          placeholder="Enter Code/name"
                        />
                      )}
                      onChange={(_, data) => {
                        onChange(data);
                        return data;
                      }}
                      defaultValue={data?.placeOfWork}
                    />
                  )}
                />
                <IconButton className="ml-2" color="primary" aria-label="add placeOfWork" onClick={handleShowPlaceOfWorkForm}>
                  <AddIcon />
                </IconButton>
              </Stack>
            </OSLForm.FormField>

            <OSLForm.FormField label="Starting Time">
              <Controller
                name="startingTime"
                control={control}
                render={({ field: { value } }) => (
                  <OSLTimePicker
                    onTimeChange={(value: string) => setValue('startingTime', value)}
                    error={!!errors.startingTime}
                    helperText={errors.startingTime?.message}
                    value={value}
                  />
                )}
              />
            </OSLForm.FormField>

            <OSLForm.FormField label="Closing Time">
              <Controller
                name="closingTime"
                control={control}
                render={({ field: { value } }) => (
                  <OSLTimePicker
                    onTimeChange={(value: string) => setValue('closingTime', value)}
                    error={!!errors.closingTime}
                    helperText={errors.closingTime?.message}
                    value={value}
                  />
                )}
              />
            </OSLForm.FormField>

            <OSLForm.FormField label="Work Details">
              <Controller
                name="workDetails"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="outlined"
                    error={!!errors.workDetails}
                    fullWidth
                    helperText={errors.workDetails?.message}
                    placeholder="Enter Work Details"
                    inputRef={ref}
                    size="small"
                    {...field}
                  />
                )}
              />
            </OSLForm.FormField>

            <OSLForm.FormField label="Idle">
              <Controller
                name="isIdle"
                control={control}
                defaultValue={false}
                render={({ field: { ref, ...field } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          py: 0
                        }}
                        ref={ref}
                        checked={field.value}
                        {...field}
                      />
                    }
                    label={
                      <Typography fontSize={12} color="text.secondary" sx={{ userSelect: 'none' }}>
                        Is Idle
                      </Typography>
                    }
                  />
                )}
              />
            </OSLForm.FormField>

            {isIdle && (
              <OSLForm.FormField label="Stoppage/Breakdown Details">
                <Controller
                  name="breakdownDetails"
                  control={control}
                  defaultValue=""
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      variant="outlined"
                      error={!!errors.breakdownDetails}
                      fullWidth
                      size="small"
                      helperText={errors.breakdownDetails?.message}
                      placeholder="Enter Stoppage/Breakdown Details"
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
              </OSLForm.FormField>
            )}

            <OSLForm.FormField label="Remarks">
              <Controller
                name="remarks"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="outlined"
                    error={!!errors.remarks}
                    fullWidth
                    multiline
                    minRows={4}
                    size="small"
                    helperText={errors.remarks?.message}
                    placeholder="Enter Remarks"
                    inputRef={ref}
                    {...field}
                  />
                )}
              />
            </OSLForm.FormField>
          </OSLForm>
        </Box>

        <OSLModal.Footer justifyContent={mobileScreen ? 'start' : 'right'}>
          <Stack direction={mobileScreen ? 'column' : 'row'} gap={4} width={mobileScreen ? '100%' : 'auto'}>
            <Button
              aria-label="cancel"
              onClick={handleClose}
              variant="outlined"
              color="primary"
              className="text-capitalize text-nowrap"
              sx={(theme) => ({
                width: 134,
                height: 50,
                borderRadius: 2,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              })}>
              <Typography>Cancel</Typography>
            </Button>
            <Button
              aria-label="save"
              variant="contained"
              color="primary"
              className="text-capitalize text-nowrap"
              disableElevation
              sx={(theme) => ({
                width: 134,
                height: 50,
                borderRadius: 2,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              })}
              type="submit"
              form="requisition-log-form">
              <Typography>Save</Typography>
            </Button>
          </Stack>
        </OSLModal.Footer>
      </OSLModal>

      <PlaceOfWorkForm //
        isOpen={showPlaceOfWorkForm}
        handleClose={() => setShowPlaceOfWorkForm(false)}
        mode="create"
      />
    </>
  );
}
