import { getAllSpareParts as getAll } from '../../../api/sparePart';
import { GET_SPARE_PARTS, SET_SPARE_PART_PAGE, SET_SPARE_PART_PER_PAGE } from './sparePart.constants';

export const getAllSpareParts = (pageNumber: number, pageSize: number) => {
  return {
    type: GET_SPARE_PARTS,
    promise: getAll(pageNumber, pageSize)
  };
};

export const setPage = (page: number) => {
  return {
    type: SET_SPARE_PART_PAGE,
    response: page
  };
};

export const setPerPage = (perPage: number) => {
  return {
    type: SET_SPARE_PART_PER_PAGE,
    response: perPage
  };
};
