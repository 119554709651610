import { SparePartTableReturnType } from '../../../models/SparePart';
import {
  GET_SPARE_PARTS_FAILURE,
  GET_SPARE_PARTS_REQUEST,
  GET_SPARE_PARTS_SUCCESS,
  SET_SPARE_PART_PAGE,
  SET_SPARE_PART_PER_PAGE
} from './sparePart.constants';

interface SparePartState {
  sparePartList: SparePartTableReturnType;
  loading: boolean;
  page: number;
  perPage: number;
}

const initialState: SparePartState = {
  sparePartList: { sparePartsList: [], totalCount: { count: 0 } },
  loading: false,
  page: 0,
  perPage: 5
};

const sparePartReducer = (state = initialState, action: { response?: any; type?: any }): SparePartState => {
  const { type } = action;

  switch (type) {
    case GET_SPARE_PARTS_REQUEST:
      return { ...state, loading: true };
    case GET_SPARE_PARTS_SUCCESS: {
      return {
        ...state,
        sparePartList: action.response,
        loading: false
      };
    }
    case GET_SPARE_PARTS_FAILURE:
      return { ...state, loading: false };

    case SET_SPARE_PART_PAGE:
      return { ...state, page: action.response };

    case SET_SPARE_PART_PER_PAGE:
      return { ...state, perPage: action.response };

    default:
      return state;
  }
};

export default sparePartReducer;
