import React, { Suspense, useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import EditIcon from '../../../../assets/EditIcon';
import TrashIcon from '../../../../assets/TrashIcon';
import Chip from '@mui/material/Chip';
import { SparePartTableSchemaType } from '../../../../models/SparePart';
import CircleIcon from '@mui/icons-material/Circle';
import Box from '@mui/material/Box';
import { formatDateString } from '~/utils/date-time-utils';
import { deleteSparePart } from '~/api/sparePart';
import { getAllSpareParts, setPage } from '../../redux/sparePart.actions';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';

const SparePartStockInForm = React.lazy(() => import('../SparePartStockInForm'));
const SparePartStockOutForm = React.lazy(() => import('../SparePartStockOutForm'));
const SparePartForm = React.lazy(() => import('../SparePartForm'));
const OSLConfirmationDialog = React.lazy(() => import('../../../../components/ComfirmationDialog'));

export default function SparePartListTableRow({ sparePart }: { sparePart: SparePartTableSchemaType }) {
  const sparePartState = useAppSelector((state) => state.sparePart);
  const { page, perPage } = sparePartState;
  const dispatch = useAppDispatch();
  const [showSparePartForm, setShowSparePartForm] = useState(false);
  const [showSparePartStockInForm, setShowSparePartStockInForm] = useState(false);
  const [showSparePartStockOutForm, setShowSparePartStockOutForm] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleCloseSparePartForm = () => {
    setShowSparePartForm(false);
  };

  const handleOpenSparePartForm = () => {
    setShowSparePartForm(true);
  };

  const handleCloseSparePartStockInForm = () => {
    setShowSparePartStockInForm(false);
  };

  const handleOpenSparePartStockInForm = () => {
    setShowSparePartStockInForm(true);
  };

  const handleCloseSparePartStockOutForm = () => {
    setShowSparePartStockOutForm(false);
  };

  const handleOpenSparePartStockOutForm = () => {
    setShowSparePartStockOutForm(true);
  };

  const handleDeleteSparePart = async () => {
    const result = await deleteSparePart(sparePart?._id);

    if (result === 'success') {
      if (page === 0) {
        dispatch(getAllSpareParts(page, perPage));
      } else {
        dispatch(setPage(0));
      }
      setShowConfirmationDialog(false);
    }
    console.error('Something went wrong');
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography color="text.secondary">{sparePart?.name}</Typography>
        </TableCell>

        <TableCell>
          <Typography color="text.secondary">{sparePart?.partNumber}</Typography>
        </TableCell>

        <TableCell align="center">
          <Typography color="text.secondary">{sparePart?.totalStock}</Typography>
        </TableCell>

        <TableCell align="center">
          <Typography color="text.secondary">{sparePart?.log ? formatDateString(sparePart?.log?.lastStockIn?.createdAt) : '-'}</Typography>
        </TableCell>

        <TableCell align="center">
          <Typography color="text.secondary">{sparePart?.log?.lastStockIn?.qty ?? '-'}</Typography>
        </TableCell>

        <TableCell>
          <Box component="div" gap="10px" display="flex" alignItems="center">
            <CircleIcon color={(sparePart?.log?.totalExpired || 0) === 0 ? 'success' : 'error'} sx={{ fontSize: 10 }} />
            <Typography color="text.secondary">
              {(sparePart?.log?.totalExpired || 0) > 0 ? `${sparePart?.log?.totalExpired} Units` : 'No Expiry'}
            </Typography>
          </Box>
        </TableCell>

        <TableCell align="center">
          <Chip
            label="Warning"
            // color={sparePart.threshold === 'Warning' ? 'warning' : sparePart.threshold === 'Safe' ? 'success' : 'error'}
            color="warning"
            size="small"
          />
        </TableCell>

        <TableCell align="center">
          <Stack spacing={1} alignItems="center">
            <Button
              className="text-capitalize text-nowrap"
              variant="contained"
              color="info"
              size="small"
              onClick={handleOpenSparePartStockInForm}
              sx={{
                width: 70,
                fontWeight: 700,
                fontSize: '10px'
              }}>
              Stock In
            </Button>
            <Button
              className="text-capitalize text-nowrap"
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpenSparePartStockOutForm}
              sx={{
                width: 70,
                fontWeight: 700,
                fontSize: '10px'
              }}>
              Stock Out
            </Button>
          </Stack>
        </TableCell>

        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <IconButton aria-label="edit" onClick={handleOpenSparePartForm}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => setShowConfirmationDialog(true)}>
              <TrashIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <Suspense>
        {showSparePartForm && (
          <SparePartForm mode="edit" data={sparePart} isOpen={showSparePartForm} handleClose={handleCloseSparePartForm} />
        )}

        {showSparePartStockInForm && (
          <SparePartStockInForm mode="edit" data={sparePart} isOpen handleClose={handleCloseSparePartStockInForm} />
        )}

        {showSparePartStockOutForm && (
          <SparePartStockOutForm mode="edit" data={sparePart} isOpen handleClose={handleCloseSparePartStockOutForm} />
        )}

        {showConfirmationDialog && (
          <OSLConfirmationDialog
            open
            title={`Delete Spare Part`}
            onCancel={() => setShowConfirmationDialog(false)}
            onOk={handleDeleteSparePart}>
            <Typography>{`Do you want to delete Spare Part: "${sparePart?.name}"?`}</Typography>
          </OSLConfirmationDialog>
        )}
      </Suspense>
    </>
  );
}
