import { axiosPrivate } from './axios';
import { AxiosError, AxiosResponse } from 'axios';
import { PrincipalCreateType } from '~/models/Principal';

export const getAllPrincipals = (pageNumber: number = 0, pageSize: number = 5) => {
  return axiosPrivate
    .get(`/principal?page=${pageNumber}&perPage=${pageSize}`)
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const addPrincipal = (principalData: PrincipalCreateType) => {
  return axiosPrivate
    .post('/principal', principalData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};
