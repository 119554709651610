import { axiosPrivate } from './axios';
import { AxiosError, AxiosResponse } from 'axios';
import { VesselCreateType } from '~/models/Vessel';

export const getAllVessels = (pageNumber: number = 0, pageSize: number = 5) => {
  return axiosPrivate
    .get(`/vessel?page=${pageNumber}&perPage=${pageSize}`)
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const addVessel = (vesselData: VesselCreateType) => {
  return axiosPrivate
    .post('/vessel', vesselData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};
