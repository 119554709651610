import { axiosPrivate } from './axios';
import { AxiosError, AxiosResponse } from 'axios';
import { OwnershipCreateType } from '~/models/Ownership';

export const getAllOwnerships = (pageNumber: number = 0, pageSize: number = 9999999) => {
  return axiosPrivate
    .get(`/ownership?page=${pageNumber}&perPage=${pageSize}`)
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const addOwnership = (ownershipData: OwnershipCreateType) => {
  return axiosPrivate
    .post('/ownership', ownershipData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

