import { axiosPrivate } from './axios';
import { AxiosError, AxiosResponse } from 'axios';

export const getAllMechanics = (pageNumber: number = 0, pageSize: number = 5) => {
  return axiosPrivate
    .get(`/mechanic?page=${pageNumber}&perPage=${pageSize}`)
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};
