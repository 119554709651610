import axios from 'axios';
import history from '~/utils/customHistory';

//const BASE_URL = process.env.NODE_ENV !== 'production' ? process.env.API_URI_DEV : process.env.API_URI_PROD;
const BASE_URL = "https://osleqms-api.invincix.in/api/v1";
export default axios.create({
  baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' }
});

axiosPrivate.interceptors.response.use(
  function (response) {
    return response;
  },
  function (er) {
    if (axios.isAxiosError(er)) {
      if (er.response) {
        if (er.response.status == 401) {
          history.replace('/auth/login');
        }
      }
    }

    return Promise.reject(er);
  }
);
