import React, { Suspense, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import EditIcon from '~/assets/EditIcon';
import TrashIcon from '~/assets/TrashIcon';
import { VehicleListState } from '../../redux/equipment.reducer';
import Box from '@mui/material/Box';
import ConfirmDialog from '~/components/ConfirmDialog';
import { setPage, getAllEquipments } from '../../redux/equipment.actions';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { formatDateString } from '~/utils/date-time-utils';
import { Vehicle } from '~/models/Equipment';
import { deleteVehicle } from '~/api/equipment';
import VehicleForm from '../VehicleForm';
import Chip from '@mui/material/Chip';

export default function VehicleListTableRow({ vehicle, vIndex }: { vehicle: VehicleListState; vIndex: number }) {
  const dispatch = useAppDispatch();
  const equipmentState = useAppSelector((state) => state.equipment);
  const { page, perPage } = equipmentState;
  const [showVehicleForm, setShowVehicleForm] = useState<boolean>(false);
  const [showConfirmDeleteVehicle, setShowConfirmDeleteVehicle] = useState<boolean>(false);
  const [vehicleData, setVehicleData] = useState<Vehicle>();

  const handleShowVehicleForm = (vehicle: Vehicle) => {
    setVehicleData(vehicle);
    setShowVehicleForm(true);
  };

  const handleShowConfirmDeleteVehicle = (vehicle: Vehicle) => {
    setVehicleData(vehicle);
    setShowConfirmDeleteVehicle(true);
  };

  const handleCloseVehicleForm = () => setShowVehicleForm(false);

  const handleDeleteVehicle = async () => {
    const result = await deleteVehicle({
      vehicleId: vehicleData?._id ?? ''
    });

    if (result === 'success') {
      if (page === 0) {
        dispatch(getAllEquipments(page, perPage));
      } else {
        dispatch(setPage(0));
      }
      setShowConfirmDeleteVehicle(false);
      return;
    }
    console.error('Something went wrong');
  };

  return (
    <>
      <TableRow hover>
        <TableCell align="center" component="th" scope="row">
          <Typography sx={{ color: 'text.secondary' }}>{vIndex + '.'}</Typography>
        </TableCell>

        <TableCell align="left">
          <Typography sx={{ color: 'text.secondary' }}>{vehicle.number}</Typography>
        </TableCell>

        <TableCell align="center">
          <Typography sx={{ color: 'text.secondary' }}>{vehicle.ownership}</Typography>
        </TableCell>

        <TableCell align="center">
          <Typography sx={{ color: 'text.secondary' }}>{formatDateString(vehicle.insuranceExpDate.toString())}</Typography>
        </TableCell>

        <TableCell align="center">
          <Typography sx={{ color: 'text.secondary' }}>{formatDateString(vehicle.pollutionExpDate.toString())}</Typography>
        </TableCell>

        <TableCell align="center">
          <Chip label={vehicle.availability ? 'FREE' : 'ACTIVE'} color={vehicle.availability ? 'success' : 'error'} size="small" />
        </TableCell>

        <TableCell align="center">
          <Stack direction="row">
            <IconButton aria-label="edit" onClick={() => handleShowVehicleForm(vehicle)}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => handleShowConfirmDeleteVehicle(vehicle)}>
              <TrashIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <Suspense>
        {showVehicleForm && (
          <VehicleForm //
            mode="edit"
            handleClose={handleCloseVehicleForm}
            isOpen
            data={
              vehicle || {
                number: '',
                _id: '',
                equipment: {
                  _id: '',
                  code: '',
                  name: ''
                },
                ownership: '',
                insuranceExpDate: new Date(),
                pollutionExpDate: new Date(),
                availability: true
              }
            }
          />
        )}

        <ConfirmDialog open={showConfirmDeleteVehicle} onClose={() => setShowConfirmDeleteVehicle(false)}>
          <ConfirmDialog.Header onClose={() => setShowConfirmDeleteVehicle(false)}>Confirm delete vehicle</ConfirmDialog.Header>
          <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" p={5}>
            <Typography variant="body1">
              Are you sure you want to delete vehicle <strong>{vehicleData?.number}</strong> ?
            </Typography>
          </Box>
          <ConfirmDialog.Footer onClose={() => setShowConfirmDeleteVehicle(false)} onOk={() => handleDeleteVehicle()} />
        </ConfirmDialog>
      </Suspense>
    </>
  );
}
