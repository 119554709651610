import TextField from '@mui/material/TextField';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { parseTimeString, timeFormatter } from '~/utils/date-time-utils';
import InputAdornment from '@mui/material/InputAdornment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Divider from '@mui/material/Divider';
import SelectButton from './SelectButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';

const ampmOptions = ['AM', 'PM'];

export default function TimePicker({
  onTimeChange,
  error,
  helperText,
  value
}: {
  onTimeChange?: (value: string) => void;
  error?: boolean;
  helperText?: string;
  value?: string | null;
}) {
  const [timeValue, setTimeValue] = useState(parseTimeString(value).time);
  const [ampm, setAmpm] = useState(parseTimeString(value).ampm);
  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  useEffect(() => {
    onTimeChange && onTimeChange([timeValue, ampm].join(' '));
  }, [timeValue, ampm]);

  const handleChangeTime = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined): void => {
    if (!event) return;
    setCursor(event.target.selectionStart === 3 ? 4 : event.target.selectionStart);
    setTimeValue(timeFormatter(event.target.value));
  };

  const handleAmpmChange = (ampm: string) => {
    setAmpm(ampm);
  };

  return (
    <>
      <TextField
        variant="outlined"
        inputRef={ref}
        size="small"
        type="tel"
        value={timeValue}
        error={error || false}
        helperText={helperText || ''}
        onChange={handleChangeTime}
        sx={{
          maxWidth: 175
        }}
        placeholder="07:00"
        inputProps={{
          sx: {
            px: 1
          }
        }}
        InputProps={{
          sx: {
            pr: 0
          },
          startAdornment: (
            <InputAdornment position="start">
              <AccessTimeIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              <Divider flexItem orientation="vertical" sx={{ borderRightWidth: 2 }} />
              <InputAdornment position="end" sx={{ ml: 0 }}>
                <SelectButton
                  disablePortal
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onItemSelect={(selected) => {
                    if (typeof selected === 'string') {
                      handleAmpmChange(selected);
                    }
                  }}
                  label={
                    <>
                      <Stack direction="column" alignItems="center" sx={{ mt: 1 }}>
                        <Typography variant="body2" color="text.secondary">
                          {ampm}
                        </Typography>
                        <KeyboardArrowDownIcon sx={(theme) => ({ fontSize: 17, mt: -0.7, color: theme.palette.text.secondary })} />
                      </Stack>
                    </>
                  }
                  endIcon={<></>}>
                  {ampmOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </SelectButton>
              </InputAdornment>
            </>
          )
        }}
      />
    </>
  );
}
