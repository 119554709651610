import { envTypes } from "./const";

const localServer = 'https://www.osl-odisha.com/api/app';
const prodServer = 'https://www.osl-odisha.com/api/test';

const isUnderDevelopment = process.env.NODE_ENV === envTypes.development;

export default {
    BASE_PATH: isUnderDevelopment ? localServer : prodServer,
    appStorageKey: 'osl_web_app_invincix',
    appName: "OSL EQMS"
};


