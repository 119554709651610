import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export default function PieChart({ ...props }) {
  const data = {
    labels: ['SAIL', 'NALCO', 'TATA', 'JINDAL', 'VEDANT'],
    datasets: [
      {
        label: '# of Votes',
        data: [30, 25, 20, 7, 18],
        backgroundColor: ['#2398ed', '#fd853a', '#2ed480', '#a4fe6d', '#6cfeed'],
        borderColor: ['#fff', '#fff', '#fff', '#fff', '#fff'],
        borderWidth: 0.5
      }
    ]
  };

  return (
    <Box
      style={{
        border: '1px solid #f0f0f0',
        borderRadius: '6px',
        backgroundColor: '#fff'
      }}
      className="p-4 m-3">
      <Typography variant="body1" component="div" noWrap style={{ color: '#707070' }}>
        <strong>Principle Against Active Hours</strong>
      </Typography>
      <div className="d-flex align-items-center justify-content-center">
        <Pie
          data={data}
          options={{
            responsive: true,
            plugins: {
              tooltip: {
                enabled: true
              },
              legend: {
                position: 'right',
                labels: {
                  usePointStyle: true,
                  pointStyle: 'circle',
                  padding: 25
                }
              },
              datalabels: {
                display: false,
                color: 'black',
                align: 'end',
                offset: 72,
                formatter: function (value, context) {
                  return value + '%';
                }
              }
            }
          }}
        />
      </div>
    </Box>
  );
}
