import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

export default function ProgressItem({ ...props }) {
  return (
    <Box
      sx={{ width: '100%', maxWidth: 250 }}
      style={{
        border: '1px solid #f0f0f0',
        borderRadius: '6px',
        backgroundColor: '#fff'
      }}
      className="p-4 m-1">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <Typography variant="caption" style={{ color: '#808191', fontSize: '11px' }}>
            <strong>{props.label}</strong>
          </Typography>
          <Typography variant="h6" component="div" noWrap>
            <strong>{props.description}</strong>
          </Typography>
        </div>
        <div>
          <Box sx={{ position: 'relative' }}>
            <CircularProgress
              variant="determinate"
              sx={{
                color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
              }}
              size={50}
              thickness={10}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                animationDuration: '0ms',
                position: 'absolute',
                left: 0,
                zIndex: 1
              }}
              color={props.circularColor}
              size={50}
              thickness={10}
            />
          </Box>
        </div>
      </div>
    </Box>
  );
}
