import { Equipment, Vehicle } from '../../../models/Equipment';
import { GET_EQUIPMENTS_FAILURE, GET_EQUIPMENTS_REQUEST, GET_EQUIPMENTS_SUCCESS } from './equipment.constants';

export type EquipmentListState = Equipment & {
  vehicles: Vehicle[];
};

export type VehicleListState = Vehicle;

interface EquipmentState {
  equipmentList: { data: EquipmentListState[]; totalCount: number };
  loading: boolean;
  page: number;
  perPage: number;
}

const initialState: EquipmentState = {
  equipmentList: { data: [], totalCount: 0 },
  loading: false,
  page: 0,
  perPage: 5
};

const equipmentReducer = (state = initialState, action: { response?: any; type?: any }): EquipmentState => {
  const { type } = action;

  switch (type) {
    case GET_EQUIPMENTS_REQUEST:
      return { ...state, loading: true };
    case GET_EQUIPMENTS_SUCCESS: {
      return {
        ...state,
        equipmentList: {
          data: action.response.equipmentList,
          totalCount: action.response.totalCount.count
        },
        loading: false
      };
    }
    case GET_EQUIPMENTS_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default equipmentReducer;
