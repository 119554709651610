import React, { useEffect } from 'react';
import type { TableCellProps } from '@mui/material/TableCell';
import EquipmentListTableRow from './EquipmentListTableRow';
import { useAppSelector, useAppDispatch } from '~/redux/hooks';
import { getAllEquipments, searchEquipments } from '../../redux/equipment.actions';
import OSLTable from '~/components/Table';

const headCells: TableCellProps[] = [
  {
    align: 'left',
    children: 'Code'
  },
  {
    align: 'left',
    children: 'Name'
  },
  {
    align: 'center',
    children: 'Qty'
  },
  {
    align: 'center',
    children: 'Vehicles'
  },
  {
    align: 'center',
    children: 'Actions'
  }
];

export default function EquipmentListTable({ searchValue = '' }: { searchValue?: string }) {
  const equipmentState = useAppSelector((state) => state.equipment);
  const { loading, equipmentList } = equipmentState;
  const dispatch = useAppDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (searchValue === '') {
      dispatch(getAllEquipments(page, rowsPerPage))
    } else {
      dispatch(searchEquipments(searchValue, page, rowsPerPage))
    }
  }, [searchValue, page, rowsPerPage])

  return (
    <>
      <OSLTable
        tableHeadCells={headCells}
        loading={loading}
        tablePagination={{
          count: equipmentList.totalCount,
          rowsPerPage,
          page,
          onPageChange: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage
        }}>
        {equipmentList?.data?.map((equip) => {
          return <EquipmentListTableRow key={equip?._id} equipment={equip} />;
        })}
      </OSLTable>
    </>
  );
}
