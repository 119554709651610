import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import styles from './styles.scss';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale);

export default function GanttChart({ ...props }) {
  const options = {
    responsive: true,
    indexAxis: 'y' as const,
    scales: {
      x: {
        min: '2022-06-12' as const,
        type: 'time' as const,
        time: {
          unit: 'day' as const
        }
      },
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: true,
        color: 'white',
        formatter: function (value: any, context: any) {
          return '3km/l';
        }
      },
      tooltip: {
        enabled: false
      }
    }
  };

  return (
    <Box
      style={{
        border: '1px solid #f0f0f0',
        borderRadius: '6px',
        backgroundColor: '#fff'
      }}
      className="p-4 m-3">
      <Typography variant="body1" component="div" noWrap style={{ color: '#707070' }}>
        <strong>{props.title}</strong>
      </Typography>
      <div className="d-flex align-items-center justify-content-center mt-5">
        <Bar options={options} data={props.data} />
      </div>
    </Box>
  );
}
