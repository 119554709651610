import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import OSLModal from '~/components/Modal';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PlaceOfWorkFormType, PlaceOfWorkSchemaType, PlaceOfWorkFormSchema } from '~/models/PlaceOfWork';
import OSLForm from '~/components/Form';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material';
import { addPlaceOfWork } from '~/api/placeOfWorks';


export default function PlaceOfWorkForm({
  isOpen,
  handleClose,
  mode,
  data
}: {
  isOpen: boolean;
  handleClose: () => void;
  mode: 'edit' | 'create';
  data?: PlaceOfWorkSchemaType | never;
}) {
  const mobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<PlaceOfWorkFormType>({
    mode: 'onChange',
    resolver: zodResolver(PlaceOfWorkFormSchema),
    defaultValues:
      mode === 'edit'
        ? data
        : {
          name: ''
        }
  });

  const onSubmit: SubmitHandler<PlaceOfWorkFormType> = async (submitData) => {
    const formData = {
      name: submitData?.name
    };
    let result;

    if (mode === 'create') {
      result = await addPlaceOfWork(formData);
    }

    if (result === 'success') {
      handleClose();
      return;
    }
    console.error('Something went wrong');
  };

  return (
    <>
      <OSLModal onClose={handleClose} open={isOpen}>
        <OSLModal.Header onClose={handleClose}>{mode === 'create' ? 'Add PlaceOfWork' : 'Edit PlaceOfWork'}</OSLModal.Header>
        <Box px={5} py={4}>
          <OSLForm
            onSubmit={handleSubmit(onSubmit)}
            id="place-of-work-form"
            fieldConfig={{ label: { md: 2.5 }, inputField: { md: 9.5 }, formItem: { columnSpacing: { md: 5 } } }}>
            <OSLForm.FormField label="Name">
              <Controller
                name="name"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="outlined"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    fullWidth
                    inputRef={ref}
                    size="small"
                    placeholder="Enter name of the place of work"
                    {...field}
                  />
                )}
              />
            </OSLForm.FormField>
          </OSLForm>
        </Box>

        <OSLModal.Footer justifyContent={mobileScreen ? 'start' : 'right'}>
          <Stack direction={mobileScreen ? 'column' : 'row'} gap={4} width={mobileScreen ? '100%' : 'auto'}>
            <Button
              aria-label="cancel"
              onClick={handleClose}
              variant="outlined"
              color="primary"
              className="text-capitalize text-nowrap"
              sx={(theme) => ({
                width: 134,
                height: 50,
                borderRadius: 2,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              })}>
              <Typography>Cancel</Typography>
            </Button>
            <Button
              aria-label="save"
              variant="contained"
              color="primary"
              className="text-capitalize text-nowrap"
              disableElevation
              sx={(theme) => ({
                width: 134,
                height: 50,
                borderRadius: 2,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              })}
              type="submit"
              form="place-of-work-form">
              <Typography>Save</Typography>
            </Button>
          </Stack>
        </OSLModal.Footer>
      </OSLModal>
    </>
  );
}
