import React, { ReactNode, useEffect } from 'react';
import SideBar from '~/components/SideBar';
import Header from '~/components/Header';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getCurrentUser } from '../Authenticate/redux/user.actions';

export default function AppContentWrapper() {
  const userState = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { getUserLoading } = userState;

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  return (
    <>
      <div className="d-flex flex-column flex-root" style={{ height: '100vh' }}>
        <div className="d-flex flex-row flex-column-fluid page h-100">
          <SideBar />
          <div className="d-flex flex-column flex-row-fluid wrapper w-100" style={{ marginLeft: '220px' }}>
            <div id="kt_content" className={`content d-flex flex-column flex-column-fluid`}>
              <div className="d-flex flex-column-fluid" style={{ backgroundColor: '#f3f5f7' }}>
                {!getUserLoading && <Header />}
                <div className="container-fluid" style={{ paddingTop: '116px' }}>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
