import React, { useState, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import OSLModal from '~/components/Modal';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { VehicleFormSchema, VehicleFormType, Vehicle } from '~/models/Equipment';
import FormControlLabel from '@mui/material/FormControlLabel';
import OSLForm from '~/components/Form';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import OSLDatePicker from '~/components/DatePicker';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useEquipments } from '../hooks/useEquipments';
import AutoComplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { addVehicle, updateVehicle } from '~/api/equipment';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { setPage, getAllEquipments } from '../redux/equipment.actions';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import EquipmentForm from '~/forms/EquipmentForm';
import OwnershipForm from '~/forms/OwnershipForm';
import { getAllOwnerships } from '~/api/ownership';
import { OwnershipSchemaType } from '~/models/Ownership';


export default function VehicleForm({
  isOpen,
  handleClose,
  mode,
  data
}: {
  isOpen: boolean;
  handleClose: () => void;
  mode: 'edit' | 'create';
  data?: Vehicle | never;
}) {
  const dispatch = useAppDispatch();
  const mobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const equipmentState = useAppSelector((state) => state.equipment);
  const [showEquipmentForm, setShowEquipmentForm] = useState<boolean>(false)
  const [showOwnershipForm, setShowOwnershipForm] = useState<boolean>(false)
  const [needLoadOwnership, setNeedLoadOwnership] = useState<boolean>(true)
  const [ownershipList, setOwnershipList] = useState<OwnershipSchemaType[]>([])
  const [ownershipLoading, setOwnershipLoading] = useState<boolean>(false)
  const { page, perPage } = equipmentState;
  const { loading: equipmentLoading, getEquipments, equipmentData } = useEquipments(0, 50);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<VehicleFormType>({
    mode: 'onChange',
    resolver: zodResolver(VehicleFormSchema),
    defaultValues:
      mode === 'edit'
        ? {
          number: data?.number || '',
          insuranceExpDate: new Date(data?.insuranceExpDate || ''),
          pollutionExpDate: new Date(data?.pollutionExpDate || ''),
          ownership: data?.ownership || 'OSL',
          equipment: equipmentData.equipmentList[0]
        }
        : {
          number: '',
          insuranceExpDate: new Date(),
          pollutionExpDate: new Date(),
          ownership: 'OSL',
          equipment: {}
        }
  });

  const onSubmit: SubmitHandler<VehicleFormType> = async (submitData) => {
    const formData = {
      equipmentId: submitData.equipment?._id,
      number: submitData.number,
      ownership: submitData.ownership,
      insuranceExpDate: submitData.insuranceExpDate,
      pollutionExpDate: submitData.pollutionExpDate
    };
    let result;

    if (mode === 'create') {
      result = await addVehicle(formData);
    }

    if (mode === 'edit') {
      result = await updateVehicle({ ...formData, vehicleId: data?._id || '' });
    }

    if (result === 'success') {
      if (page === 0) {
        dispatch(getAllEquipments(page, perPage));
      } else {
        dispatch(setPage(0));
      }
      handleClose();
      return;
    }
    console.error('Something went wrong');
  };

  const handleShowEquipmentForm = () => {
    setShowEquipmentForm(true)
  }

  const handleShowOwnershipForm = () => {
    setShowOwnershipForm(true)
  }

  useEffect(() => {
    if (needLoadOwnership) {
      setOwnershipLoading(true)
      getAllOwnerships()
        .then((res) => {
          setOwnershipList(res.ownershipList)
          setNeedLoadOwnership(false)
          setOwnershipLoading(false)
        })
        .catch(err => {
          console.error(err)
          setNeedLoadOwnership(false)
          setOwnershipLoading(false)
        })
    }
  }, [needLoadOwnership])

  return (
    <>
      <OSLModal onClose={handleClose} open={isOpen}>
        <OSLModal.Header onClose={handleClose}>{mode === 'create' ? 'Add Vehicle' : 'Edit Vehicle'}</OSLModal.Header>
        <Box px={5} py={4}>
          <OSLForm
            onSubmit={handleSubmit(onSubmit)}
            id="vehicle-form"
            fieldConfig={{ label: { md: 2.5 }, inputField: { md: 9.5 }, formItem: { columnSpacing: { md: 5 } } }}>
            <OSLForm.FormField label="Code/Name">
              <Stack direction="row" alignItems="center">
                <Controller
                  name="equipment"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <AutoComplete
                      id="equipment"
                      fullWidth
                      onOpen={() => {
                        getEquipments();
                      }}
                      loading={equipmentLoading}
                      options={equipmentData.equipmentList || []}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option._id === value._id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {equipmentLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                          error={!!errors.equipment}
                          helperText={errors.equipment?.message}
                          placeholder="Enter Code/name"
                        />
                      )}
                      onChange={(_, data) => {
                        onChange(data);
                        return data;
                      }}
                      defaultValue={equipmentData.equipmentList[0]}
                    />
                  )}
                />
                <IconButton
                  className="ml-2"
                  color="primary"
                  aria-label="add equipment"
                  onClick={handleShowEquipmentForm}>
                  <AddIcon />
                </IconButton>
              </Stack>
            </OSLForm.FormField>

            <OSLForm.FormField label="Vehicle Number">
              <Controller
                name="number"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="outlined"
                    error={!!errors.number}
                    helperText={errors.number?.message}
                    fullWidth
                    inputRef={ref}
                    size="small"
                    placeholder="Enter Vehicle Number"
                    {...field}
                  />
                )}
              />
            </OSLForm.FormField>

            <OSLForm.FormField label="Ownership">
              {ownershipLoading ? <CircularProgress /> : <Stack direction="row" alignItems="center">
                <Controller
                  name="ownership"
                  control={control}
                  render={({ field: { ref, onChange, ...field } }) => {
                    return (
                      <FormControl component="fieldset" error={!!errors.ownership}>
                        <RadioGroup
                          row
                          aria-labelledby="ownership"
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          {...field}>
                          {ownershipList.map((o, i) => (
                            <FormControlLabel
                              key={o._id}
                              value={o.name}
                              control={<Radio size="small" />}
                              label={<Typography variant="body2">{o.name}</Typography>}
                            />
                          ))}
                        </RadioGroup>
                        <FormHelperText>{errors.ownership?.message}</FormHelperText>
                      </FormControl>
                    );
                  }}
                />
                <IconButton
                  className="ml-2"
                  color="primary"
                  aria-label="add ownership"
                  onClick={handleShowOwnershipForm}>
                  <AddIcon />
                </IconButton>
              </Stack>}
            </OSLForm.FormField>

            <OSLForm.FormField label="Insurance Expiry Date">
              <Controller
                name="insuranceExpDate"
                control={control}
                render={({ field: { ref, value, onChange, ...field } }) => (
                  <OSLDatePicker
                    inputProps={{ ...field, error: !!errors.insuranceExpDate, helperText: errors.insuranceExpDate?.message, ref: ref }}
                    datePickerProps={{ value: value, onChange: onChange }}
                  />
                )}
              />
            </OSLForm.FormField>

            <OSLForm.FormField label="Pollution Expiry Date">
              <Controller
                name="pollutionExpDate"
                control={control}
                render={({ field: { ref, value, onChange, ...field } }) => (
                  <OSLDatePicker
                    inputProps={{ ...field, error: !!errors.pollutionExpDate, helperText: errors.pollutionExpDate?.message, ref: ref }}
                    datePickerProps={{ value: value, onChange: onChange }}
                  />
                )}
              />
            </OSLForm.FormField>
          </OSLForm>
        </Box>

        <OSLModal.Footer justifyContent={mobileScreen ? 'start' : 'right'}>
          <Stack direction={mobileScreen ? 'column' : 'row'} gap={4} width={mobileScreen ? '100%' : 'auto'}>
            <Button
              aria-label="cancel"
              onClick={handleClose}
              variant="outlined"
              color="primary"
              className="text-capitalize text-nowrap"
              sx={(theme) => ({
                width: 134,
                height: 50,
                borderRadius: 2,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              })}>
              <Typography>Cancel</Typography>
            </Button>
            <Button
              aria-label="save"
              variant="contained"
              color="primary"
              className="text-capitalize text-nowrap"
              disableElevation
              sx={(theme) => ({
                width: 134,
                height: 50,
                borderRadius: 2,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              })}
              type="submit"
              form="vehicle-form">
              <Typography>Save</Typography>
            </Button>
          </Stack>
        </OSLModal.Footer>
      </OSLModal>

      <EquipmentForm isOpen={showEquipmentForm} handleClose={() => setShowEquipmentForm(false)} mode="create" />
      <OwnershipForm //
        setNeedReload={setNeedLoadOwnership}
        isOpen={showOwnershipForm}
        handleClose={() => setShowOwnershipForm(false)}
        mode="create"
      />
    </>
  );
}
