import React, { useEffect, useState } from 'react';
import Table, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import EditIcon from '../../../../assets/EditIcon';
import TrashIcon from '../../../../assets/TrashIcon';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { getRequisitionLog } from '../../redux/requisition.actions';
import { useAppDispatch } from '../../../../redux/hooks';
import Collapse from '@mui/material/Collapse';
import RequisitionLogForm from '../RequisitionLogForm';
import { timeFormatter } from '~/utils/date-time-utils';
import { RequisitionLog } from '~/models/Requisition';
import ConfirmDialog from '~/components/ConfirmDialog';
import { Box } from '@mui/material';
import { deleteRequistionLog } from '~/api/requisitionLog';
import { useAppSelector } from '~/redux/hooks';
import { setPage, getAllRequisitions } from '../../redux/requisition.actions';

const rows: TableCellProps[] = [
  {
    align: 'center',
    children: 'Sl. No.'
  },
  {
    align: 'center',
    children: 'Place of Work'
  },
  {
    align: 'center',
    children: 'Starting Time'
  },
  {
    align: 'center',
    children: 'Closing Time'
  },
  {
    align: 'center',
    children: 'Idle'
  },
  {
    align: 'left',
    children: 'Work Details'
  },
  {
    align: 'left',
    children: 'Stoppage/Breakdown Details'
  },
  {
    align: 'center',
    children: 'Remarks'
  },
  {
    align: 'left',
    children: 'Action'
  }
];

export default function RequisitionLogListTable({
  requisitionLogList,
  requisitionId,
  ...props
}: {
  requisitionId: string;
  requisitionLogList?: RequisitionLog[];
} & TableProps) {
  const dispatch = useAppDispatch();
  const requisitionState = useAppSelector((state) => state.requisition);
  const { page, perPage } = requisitionState;
  const [showLogForm, setShowLogForm] = useState<boolean>(false);
  const [logData, setLogData] = useState<RequisitionLog>();
  const [showConfirmDeleteLog, setShowConfirmDeleteLog] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getRequisitionLog(requisitionId));
  }, [requisitionId, dispatch]);

  const handleShowLogForm = (log: RequisitionLog) => {
    setLogData(log);
    setShowLogForm(true);
  };

  const handleCloseLogForm = () => setShowLogForm(false);

  const handleShowConfirmDeleteLog = (log: RequisitionLog) => {
    setLogData(log);
    setShowConfirmDeleteLog(true);
  };

  const handleDeleteLog = async () => {
    const result = await deleteRequistionLog({
      logId: logData?._id ?? ''
    });

    if (result === 'success') {
      if (page === 0) {
        dispatch(getAllRequisitions(page, perPage));
      } else {
        dispatch(setPage(0));
      }
      setShowConfirmDeleteLog(false);
      return;
    }
    console.error('Something went wrong');
  };

  return (
    <>
      <Collapse in={!!requisitionLogList} timeout="auto">
        {requisitionLogList ? (
          <TableContainer component={Paper} elevation={0}>
            <Table {...props}>
              <TableHead>
                <TableRow>
                  {(rows || []).map((row, index) => (
                    <TableCell key={index} align={row.align}>
                      <Typography sx={{ fontWeight: 500, color: 'text.secondary' }}>{row.children}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(requisitionLogList || [])?.map((log, logIndex) => (
                  <TableRow key={log._id} hover>
                    <TableCell align="center" component="th" scope="row">
                      <Typography sx={{ color: 'text.secondary' }}>{logIndex + '.'}</Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography
                        sx={{
                          color: 'text.secondary',
                          textTransform: 'uppercase'
                        }}>
                        {log.logData.place_of_work}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography sx={{ color: 'text.secondary' }}>{timeFormatter(log.logData.startingTime)}</Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography sx={{ color: 'text.secondary' }}>{timeFormatter(log.logData.closingTime)}</Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Chip
                        className="text-capitalize"
                        label={log.logData.isIdle ? 'Is Idle' : 'Not Idle'}
                        color={log.logData.isIdle ? 'error' : 'success'}
                      />
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ color: 'text.secondary' }}>{log.logData.workDetails}</Typography>
                    </TableCell>

                    <TableCell align={!!log.logData.breakdownDetails ? 'left' : 'center'}>
                      <Typography sx={{ color: 'text.secondary' }}>{log.logData.breakdownDetails ?? '-'}</Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography sx={{ color: 'text.secondary' }}>{log.logData.remarks ?? '-'}</Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Stack direction="row">
                        <IconButton aria-label="edit" onClick={() => handleShowLogForm(log)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={() => handleShowConfirmDeleteLog(log)}>
                          <TrashIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          ''
        )}
      </Collapse>

      <RequisitionLogForm
        requisitionId={requisitionId}
        mode="edit"
        handleClose={handleCloseLogForm}
        isOpen={showLogForm}
        data={logData || {}}
        logId={logData?._id}
      />

      <ConfirmDialog open={showConfirmDeleteLog} onClose={() => setShowConfirmDeleteLog(false)}>
        <ConfirmDialog.Header onClose={() => setShowConfirmDeleteLog(false)}>Confirm delete log</ConfirmDialog.Header>
        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" p={5}>
          <Typography variant="body1">
            Are you sure you want to delete log <strong>{logData?._id}</strong> ?
          </Typography>
        </Box>
        <ConfirmDialog.Footer onClose={() => setShowConfirmDeleteLog(false)} onOk={() => handleDeleteLog()} />
      </ConfirmDialog>
    </>
  );
}
