import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { getSidebarMenus } from '~/config/menus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Typography } from '@mui/material';
import { checkIsActive, checkSubMenuIsActive } from '~/helpers/RouterHelpers';
import logo from '~/assets/images/OSL_logo.png';
import { useAppSelector } from '~/redux/hooks';

export default function SideBar({ ...props }) {
  const userState = useAppSelector((state) => state.user);
  const { userInfo } = userState;
  const [sidebarMenus, setSidebarMenus] = useState<Record<string, any>[]>([]);
  const [submenuOpen, setSubmenuOpen] = useState<Record<string, boolean>>({});
  const location = useLocation();
  const getMenuItemActive = (url: string) => {
    return checkIsActive(location, url) ? 'menu-item-active' : '';
  };

  const handleOpenSubmenu = (path: string, open: boolean) => {
    setSubmenuOpen((submenuOpening) => ({
      ...submenuOpening,
      [path]: open
    }));
  };

  const getSubMenuActive = (url: string) => {
    return checkSubMenuIsActive(location, url) || submenuOpen[url];
  };

  useEffect(() => {
    userInfo && !sidebarMenus.length && setSidebarMenus(getSidebarMenus(userInfo.role.permissions));
  }, [userInfo]);

  return (
    <ProSidebar //
      breakPoint="md"
      style={{ position: 'fixed', zIndex: 1, top: 0, left: 0 }}
      width="220px">
      <SidebarHeader>
        <div className="text-center my-2">
          <img src={logo} width={70} height="auto" alt="Logo" />
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu>
          {sidebarMenus.map((menu, i) =>
            (menu?.subMenus ?? [])?.length > 0 ? (
              <SubMenu //
                key={menu.title + i}
                title={menu.title}
                open={getSubMenuActive(menu.path)}
                onOpenChange={(open) => handleOpenSubmenu(menu.path, open)}
                icon={<FontAwesomeIcon icon={menu.icon} />}>
                {(menu?.subMenus ?? []).map((subMenu: any, i: number) => (
                  <MenuItem
                    key={subMenu.title + i}
                    className={`${getMenuItemActive(subMenu.path)}`}
                    icon={
                      <FontAwesomeIcon //
                        icon={faCircle}
                        style={{ fontSize: '6px', color: checkIsActive(location, subMenu.path) ? '#ee2128' : 'inherit' }}
                      />
                    }>
                    <Link to={subMenu.path}>{subMenu.title}</Link>
                  </MenuItem>
                ))}
              </SubMenu>
            ) : (
              <MenuItem //
                key={menu.title + i}
                icon={<FontAwesomeIcon icon={menu.icon} style={{ color: checkIsActive(location, menu.path) ? '#ee2128' : 'inherit' }} />}
                className={`${getMenuItemActive(menu.path)}`}>
                <Link to={menu.path}>{menu.title}</Link>
              </MenuItem>
            )
          )}
        </Menu>
      </SidebarContent>
      <SidebarFooter className="text-center py-2">
        <Typography variant="caption">&copy; Copyright 2022</Typography>
      </SidebarFooter>
    </ProSidebar>
  );
}
