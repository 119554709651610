import React, { useEffect } from 'react';
import type { TableCellProps } from '@mui/material/TableCell';
import SparePartListTableRow from './SparePartListTableRow';
import { useAppSelector, useAppDispatch } from '~/redux/hooks';
import { getAllSpareParts, setPage, setPerPage } from '../../redux/sparePart.actions';
import OSLTable from '~/components/Table';

const headCells: TableCellProps[] = [
  {
    align: 'left',
    children: 'Name'
  },
  {
    align: 'left',
    children: 'Part No.'
  },
  {
    align: 'center',
    children: 'Total Stock'
  },
  {
    align: 'center',
    children: 'Last Received Date'
  },
  {
    align: 'center',
    children: 'Last Received Qty'
  },
  {
    align: 'left',
    children: 'Expiry'
  },
  {
    align: 'center',
    children: 'Threshold'
  },
  {
    align: 'center',
    children: 'Entry'
  },
  {
    align: 'center',
    children: 'Action'
  }
];

export default function SparePartListTable() {
  const sparePartState = useAppSelector((state) => state.sparePart);
  const { loading, sparePartList, page, perPage } = sparePartState;
  const dispatch = useAppDispatch();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
  };

  useEffect(() => {
    dispatch(getAllSpareParts(page, perPage));
  }, [page, perPage]);

  return (
    <>
      <OSLTable
        tableHeadCells={headCells}
        loading={loading}
        tablePagination={{
          count: sparePartList.totalCount?.count || 0,
          rowsPerPage: perPage,
          page,
          onPageChange: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage
        }}>
        {sparePartList?.sparePartsList?.map((sparePart) => (
          <SparePartListTableRow key={sparePart?._id} sparePart={sparePart} />
        ))}
      </OSLTable>
    </>
  );
}
