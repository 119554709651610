import { Box, CircularProgress } from '@mui/material';
import React from 'react'

function Loader() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <CircularProgress />
      <h5 style={{marginTop: 20}}>Hold on Making everything ready...</h5>
    </Box>
  )
}

export default Loader;