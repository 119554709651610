import React, { useState } from 'react';
import MainScreen from '../../MainScreen/View/MainScreen';
import styles from './Requisition.module.scss';

import RequisitionListTable from '../Components/RequisitionListTable';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import RequisitionForm from '../Components/RequisitionForm';
import SelectButton from '~/components/SelectButton';
import Search from '~/components/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export function Requisition() {
  const [showCreateForm, setShowCreateForm] = useState(false);

  return (
    <>
      <MainScreen title="Requisition">
        <div>
          <Box component="div" display="flex" flexDirection="column">
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  alignItems: 'start',
                  gap: 3,
                  px: 0,
                  mx: 3
                },
                flexDirection: 'row',
                alignItems: 'center',
                gap: 0,
                p: 3.75
              })}>
              <Box width={{ xs: '100%', sm: '100%', md: 'auto' }}>
                <Search />
              </Box>
              <Box
                display="flex"
                sx={(theme) => ({
                  [theme.breakpoints.down('md')]: { gap: 2, flexDirection: 'column-reverse' },
                  gap: 5,
                  flexDirection: 'row'
                })}>
                <Stack direction="row" gap={1}>
                  {/* <SelectButton
                    variant="outlined"
                    color="primary"
                    label={<span className="text-capitalize">Export</span>}
                    sx={{ borderRadius: 2, height: 50, width: 'fit-content' }}
                    startIcon={(open) => (
                      <KeyboardArrowDownIcon
                        fontSize="small"
                        sx={(theme) => ({
                          transition: theme.transitions.create(['transform'], {
                            duration: theme.transitions.duration.short
                          }),
                          transform: open ? 'rotate(180deg)' : 'rotate(0)',
                          color: theme.palette.text.primary
                        })}
                      />
                    )}
                    endIcon={<></>}
                  /> */}
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-capitalize"
                    sx={{
                      width: 'fit-content',
                      height: 50,
                      borderRadius: 2
                    }}
                    startIcon={<AddIcon fontSize="small" />}
                    disableElevation
                    onClick={() => setShowCreateForm(true)}>
                    Add New
                  </Button>
                </Stack>
              </Box>
            </Box>
            <RequisitionListTable />
          </Box>
        </div>
      </MainScreen>

      <RequisitionForm mode="create" isOpen={showCreateForm} handleClose={() => setShowCreateForm(false)} />
    </>
  );
}
