import React, { useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import EditIcon from '../../../../assets/EditIcon';
import TrashIcon from '../../../../assets/TrashIcon';
import UptrendIcon from '@mui/icons-material/NorthEast';
import DowntrendIcon from '@mui/icons-material/SouthWest';
import Chip from '@mui/material/Chip';
import RequisitionLogListTable from './RequisitionLogListTable';
import Collapse from '@mui/material/Collapse';
import RequisitionForm from '../RequisitionForm';
import type { RequisitionListState } from '../../redux/requisition.reducer';
import RequisitionLogForm from '../RequisitionLogForm';
import { formatDateTimeString, timeFormatter } from '~/utils/date-time-utils';
import { Requisition } from '~/models/Requisition';
import ConfirmDialog from '~/components/ConfirmDialog';
import { deleteRequisition } from '~/api/requisitions';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { setPage, getAllRequisitions } from '../../redux/requisition.actions';
import { getRequisitionStatusColor } from '~/config/const';

export default function RequisitionListTableRow({ requisition }: { requisition: RequisitionListState }) {
  const dispatch = useAppDispatch();
  const requisitionState = useAppSelector((state) => state.requisition);
  const { page, perPage } = requisitionState;
  const isLogData = !!requisition?.log;
  const [showLogTable, setShowLogTable] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [showLogForm, setShowLogForm] = useState<boolean>(false);
  const [requisitionData, setRequisitionData] = useState<Requisition>();
  const [showConfirmDeleteRequisition, setShowConfirmDeleteRequisition] = useState<boolean>(false);
  const [displayedRequisitionId, setDisplayedRequisitionId] = useState<string>('');

  const handleShowLogTable = () => {
    setShowLogTable((isShowing) => !isShowing);
  };

  const handleShowEditForm = () => setShowEditForm(true);
  const handleCloseEditForm = () => setShowEditForm(false);

  const handleShowLogForm = (requisitionId: string) => {
    setDisplayedRequisitionId(requisitionId);
    setShowLogForm(true);
  };
  const handleCloseLogForm = () => setShowLogForm(false);

  const handleShowConfirmDeleteRequisition = (requisition: Requisition) => {
    setRequisitionData(requisition);
    setShowConfirmDeleteRequisition(true);
  };

  const handleDeleteRequisition = async () => {
    const result = await deleteRequisition({
      requisitionId: requisitionData?._id ?? ''
    });

    if (result === 'success') {
      if (page === 0) {
        dispatch(getAllRequisitions(page, perPage));
      } else {
        dispatch(setPage(0));
      }
      setShowConfirmDeleteRequisition(false);
      return;
    }
    console.error('Something went wrong');
  };

  return (
    <>
      <TableRow hover>
        <TableCell component="th" scope="row">
          <Typography sx={{ color: 'text.secondary' }}>{formatDateTimeString(requisition?.createdAt || '')}</Typography>
        </TableCell>

        <TableCell>
          <Typography sx={{ color: 'text.secondary' }}>{requisition?.equipment?.name?.toUpperCase()}</Typography>
        </TableCell>

        <TableCell align="center">
          <Typography sx={{ color: 'text.secondary' }}>{requisition?.ac?.toUpperCase()}</Typography>
        </TableCell>

        <TableCell style={{ whiteSpace: 'break-spaces' }}>
          <Typography sx={{ color: 'text.secondary' }}>{requisition?.givenBy + '\n' + timeFormatter(requisition?.givenAt)}</Typography>
        </TableCell>

        <TableCell style={{ whiteSpace: 'break-spaces' }}>
          <Typography sx={{ color: 'text.secondary' }}>
            {requisition?.receivedBy + '\n' + timeFormatter(requisition?.receivedAt)}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography sx={{ color: 'text.secondary' }}>{requisition?.vessel?.name}</Typography>
        </TableCell>

        <TableCell>
          <Typography sx={{ color: 'text.secondary' }}>{requisition?.principal?.name?.toUpperCase()}</Typography>
        </TableCell>

        <TableCell align="center">
          {requisition.cargo === 'EXPORT' ? (
            <UptrendIcon color="error" fontSize="small" />
          ) : (
            <DowntrendIcon color="success" fontSize="small" />
          )}
        </TableCell>

        <TableCell align="center">
          <Typography sx={{ color: 'text.secondary' }}>{timeFormatter(requisition?.marchingTime) ?? '-'}</Typography>
        </TableCell>

        <TableCell align="left">
          <Typography sx={{ color: 'text.secondary' }}>{requisition?.operator}</Typography>
        </TableCell>

        <TableCell align="center">
          <Typography sx={{ color: 'text.secondary' }}>{timeFormatter(requisition?.reportingTime) ?? '-'}</Typography>
        </TableCell>

        <TableCell align="center">
          {
            <Chip
              className="text-capitalize"
              label={requisition?.status}
              color={getRequisitionStatusColor(requisition?.status || '')}
              size="small"
              style={
                requisition?.status === 'BLOCKED'
                  ? { backgroundColor: '#000', padding: 8, width: 'fit-content' }
                  : { padding: 8, width: 'fit-content' }
              }
            />
          }
        </TableCell>

        <TableCell align="center">
          <Stack spacing={1} alignItems="center">
            <Button
              className="text-capitalize text-nowrap"
              variant="contained"
              color="primary"
              size="small"
              sx={{
                width: 82.5,
                fontWeight: 700,
                fontSize: '10px'
              }}
              onClick={() => handleShowLogForm(requisition?._id)}>
              Add Log
            </Button>
            <Button
              className="text-capitalize text-nowrap"
              variant="contained"
              color="info"
              size="small"
              onClick={handleShowLogTable}
              disabled={!isLogData}
              sx={{
                width: 82.5,
                fontWeight: 700,
                fontSize: '10px'
              }}>
              {showLogTable ? 'Close Log' : 'View Log'}
            </Button>
          </Stack>
        </TableCell>

        <TableCell align="center">
          <Stack direction="row">
            <IconButton aria-label="edit" onClick={handleShowEditForm}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => handleShowConfirmDeleteRequisition(requisition)}>
              <TrashIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          sx={{
            padding: 0,
            border: 0,
            borderLeft: 3,
            borderLeftColor: showLogTable && requisition?.log ? 'primary.main' : 'transparent',
            boxShadow: 'none'
          }}
          colSpan={14}>
          <Collapse in={showLogTable} unmountOnExit>
            <RequisitionLogListTable
              key={requisition?._id}
              requisitionId={requisition?._id}
              requisitionLogList={requisition?.log}
              aria-label="requisition log table"
              sx={{
                width: '100%'
              }}
            />
          </Collapse>
        </TableCell>
      </TableRow>

      <RequisitionForm
        mode="edit"
        data={{ ...requisition, requisitionId: requisition?._id }}
        handleClose={handleCloseEditForm}
        isOpen={showEditForm}
      />

      <RequisitionLogForm //
        requisitionId={displayedRequisitionId}
        mode="create"
        handleClose={handleCloseLogForm}
        isOpen={showLogForm}
      />

      <ConfirmDialog open={showConfirmDeleteRequisition} onClose={() => setShowConfirmDeleteRequisition(false)}>
        <ConfirmDialog.Header onClose={() => setShowConfirmDeleteRequisition(false)}>Confirm delete requisition</ConfirmDialog.Header>
        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" p={5}>
          <Typography variant="body1">
            Are you sure you want to delete requisition <strong>{requisitionData?._id}</strong> ?
          </Typography>
        </Box>
        <ConfirmDialog.Footer onClose={() => setShowConfirmDeleteRequisition(false)} onOk={() => handleDeleteRequisition()} />
      </ConfirmDialog>
    </>
  );
}
