import { object, string, infer as Infer, array } from 'zod';

export const timeValidation = string().regex(/^(1[0-2]|0?[1-9]):([0-5]?[0-9]) [AP]M$/, { message: 'Please enter a valid time' });

export const PlaceOfWorkSchema = object({
  _id: string(),
  name: string(),
  createdAt: timeValidation,
  updatedAt: timeValidation
});

export type PlaceOfWorkSchemaType = Infer<typeof PlaceOfWorkSchema>;

export type PlaceOfWorkReturnType = {
  placeOfWorksList: PlaceOfWorkSchemaType[];
  totalCount: {
    count: number;
  };
};

export const PlaceOfWorkFormSchema = PlaceOfWorkSchema.omit({ createdAt: true, _id: true, updatedAt: true });
export const PlaceOfWorkCreateSchema = PlaceOfWorkFormSchema;

export type PlaceOfWorkFormType = Infer<typeof PlaceOfWorkFormSchema>;
export type PlaceOfWorkCreateType = Infer<typeof PlaceOfWorkCreateSchema>;