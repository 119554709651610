import React, { useEffect } from 'react';
import type { TableCellProps } from '@mui/material/TableCell';
import RequisitionListTableRow from './RequisitionListTableRow';
import { useAppSelector, useAppDispatch } from '~/redux/hooks';
import { getAllRequisitions } from '../../redux/requisition.actions';
import OSLTable from '~/components/Table';

const rows: TableCellProps[] = [
  {
    align: 'left',
    children: 'Date/Time'
  },
  {
    align: 'left',
    children: 'Equipment'
  },
  {
    align: 'left',
    children: 'A/C'
  },
  {
    align: 'left',
    children: 'Given By'
  },
  {
    align: 'left',
    children: 'Received By'
  },
  {
    align: 'left',
    children: 'Vessel'
  },
  {
    align: 'left',
    children: 'Principal'
  },
  {
    align: 'center',
    children: 'Cargo'
  },
  {
    align: 'center',
    children: 'Marching Time'
  },
  {
    align: 'left',
    children: 'Operator'
  },
  {
    align: 'center',
    children: 'Reporting Time'
  },
  {
    align: 'center',
    children: 'Status',
    style: {
      minWidth: 150
    }
  },
  {
    align: 'center',
    children: 'Log'
  },
  {
    align: 'left',
    children: 'Action'
  }
];

export default function RequisitionListTable() {
  const requisitionState = useAppSelector((state) => state.requisition);
  const { loading, requisitionList } = requisitionState;
  const dispatch = useAppDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(getAllRequisitions(page, rowsPerPage));
  }, [page, rowsPerPage, dispatch]);

  return (
    <>
      <OSLTable
        tableHeadCells={rows}
        loading={loading}
        tablePagination={{
          count: requisitionList?.totalCount?.count || 0,
          rowsPerPage,
          page,
          onPageChange: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage
        }}>
        {requisitionList?.requisitionList?.map((resq) => (
          <RequisitionListTableRow key={resq?._id} requisition={resq} />
        ))}
      </OSLTable>
    </>
  );
}
