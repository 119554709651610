import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ChangeEvent, useEffect, useState } from 'react';
import { setSearchKeys } from '../redux/maintenance.actions';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';

export const SEARCH_BY = {
  all: { title: 'All', value: ['number', 'service'] },
  number: {
    title: 'Maintenance Req. No.',
    value: 'number'
  },
  service: {
    title: 'Service Req. No.',
    value: 'service'
  }
};

export default function MaintenanceFilter() {
  const dispatch = useAppDispatch();
  const searchBy = useAppSelector((state) => state.maintenance.searchKeys);

  const handleSearchByChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchKeys(SEARCH_BY[event.target.value as keyof typeof SEARCH_BY].value));
  };

  return (
    <Stack spacing={2} m={2}>
      <FormControl component="fieldset">
        <FormLabel>Search By</FormLabel>
        <RadioGroup row aria-labelledby="searchBy" onChange={handleSearchByChange} value={Array.isArray(searchBy) ? 'all' : searchBy}>
          {Object.keys(SEARCH_BY).map((k) => (
            <FormControlLabel
              key={k}
              value={k}
              control={<Radio size="small" />}
              label={<Typography variant="body2">{SEARCH_BY[k as keyof typeof SEARCH_BY].title}</Typography>}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}
