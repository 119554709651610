import { useState } from 'react';
import { PlaceOfWorkReturnType } from '~/models/PlaceOfWork';
import { getAllPlaceOfWorks } from '~/api/placeOfWorks';

export function usePlaceOfWorks(page: number, perPage: number) {
  const [pageN, setPageN] = useState(page);
  const [pageSize, setPageSize] = useState(perPage);
  const [loading, setLoading] = useState(false);
  const [placeOfWorkData, setPlaceOfWorkData] = useState<PlaceOfWorkReturnType>({ placeOfWorksList: [], totalCount: { count: 0 } });

  const getPlaceOfWorks = async () => {
    setLoading(true);
    const result = await getAllPlaceOfWorks(pageN, pageSize);
    setPlaceOfWorkData(result);
    setLoading(false);
  };

  const getNextPage = async () => {
    const total = placeOfWorkData?.totalCount?.count;
    if (total && total > (pageN + 1) * pageSize) {
      setLoading(true);
      const result = await getAllPlaceOfWorks(pageN + 1, pageSize);
      setPageN((pagen) => pagen + 1);
      setPlaceOfWorkData((list) => ({ ...list, placeOfWorkData: [...list.placeOfWorksList, ...result.placeOfWorkData] }));
      setLoading(false);
    }
  };

  return {
    placeOfWorkData,
    loading,
    getPlaceOfWorks,
    getNextPage
  };
}
