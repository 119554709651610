import React, { Suspense, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import EditIcon from '../../../../assets/EditIcon';
import TrashIcon from '../../../../assets/TrashIcon';
import { MaintenanceSchemaType } from '../../../../models/Maintenance';
import { getAllMaintenanceReqs, setPage } from '../../redux/maintenance.actions';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { deleteMaintenance } from '~/api/maintenance';
import Chip from '@mui/material/Chip';
import { MaintenanceStatuses } from '../MaintenanceForm';

const MaintenanceForm = React.lazy(() => import('../MaintenanceForm'));
const OSLConfirmationDialog = React.lazy(() => import('../../../../components/ComfirmationDialog'));

export default function MaintenanceListTableRow({ maintenanceReq }: { maintenanceReq: MaintenanceSchemaType }) {
  const maintenanceState = useAppSelector((state) => state.maintenance);
  const { page, perPage } = maintenanceState;
  const dispatch = useAppDispatch();
  const [showForm, setShowForm] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleOpenMaintenanceForm = () => {
    setShowForm(true);
  };

  const handleCloseMaintenanceForm = () => {
    setShowForm(false);
  };

  const handleDeleteMaintenance = async () => {
    const result = await deleteMaintenance(maintenanceReq?._id);

    if (result === 'success') {
      if (page === 0) {
        dispatch(getAllMaintenanceReqs(page, perPage));
      } else {
        dispatch(setPage(0));
      }
      setShowConfirmationDialog(false);
    }
    console.error('Something went wrong');
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography color="text.secondary">{maintenanceReq?.number}</Typography>
        </TableCell>

        <TableCell>
          <Typography color="text.secondary">{maintenanceReq?.service}</Typography>
        </TableCell>

        <TableCell>
          <Typography color="text.secondary">{maintenanceReq?.detail}</Typography>
        </TableCell>

        <TableCell>
          <Typography color="text.secondary">{maintenanceReq?.type}</Typography>
        </TableCell>

        <TableCell align="center">
          {
            <Chip
              className="text-capitalize"
              label={maintenanceReq.status}
              color={MaintenanceStatuses.filter((o) => maintenanceReq.status === o.title)?.[0]?.color}
              size="small"
            />
          }
        </TableCell>

        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <IconButton aria-label="edit" onClick={handleOpenMaintenanceForm}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => setShowConfirmationDialog(true)}>
              <TrashIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <Suspense>
        {showForm && <MaintenanceForm mode="edit" isOpen data={maintenanceReq} handleClose={handleCloseMaintenanceForm} />}

        {showConfirmationDialog && (
          <OSLConfirmationDialog
            open
            title={`Delete Maintenance`}
            onCancel={() => setShowConfirmationDialog(false)}
            onOk={handleDeleteMaintenance}>
            <Typography>{`Do you want to delete Maintenance Number: ${maintenanceReq?.number}?`}</Typography>
          </OSLConfirmationDialog>
        )}
      </Suspense>
    </>
  );
}
