import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function TrashIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M14 10L14 17"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10L10 17"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6V5.5C5.72386 5.5 5.5 5.72386 5.5 6H6ZM18 6H18.5C18.5 5.72386 18.2761 5.5 18 5.5V6ZM6 6.5H18V5.5H6V6.5ZM17.5 6V20H18.5V6H17.5ZM17 20.5H7V21.5H17V20.5ZM6.5 20V6H5.5V20H6.5ZM7 20.5C6.72386 20.5 6.5 20.2761 6.5 20H5.5C5.5 20.8284 6.17157 21.5 7 21.5V20.5ZM17.5 20C17.5 20.2761 17.2761 20.5 17 20.5V21.5C17.8284 21.5 18.5 20.8284 18.5 20H17.5Z"
        fill="#707070"
      />
      <path
        d="M4 6H20"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 6V6.5C16.2761 6.5 16.5 6.27614 16.5 6H16ZM8 6H7.5C7.5 6.27614 7.72386 6.5 8 6.5V6ZM9 3.5H15V2.5H9V3.5ZM15.5 4V6H16.5V4H15.5ZM16 5.5H8V6.5H16V5.5ZM8.5 6V4H7.5V6H8.5ZM15 3.5C15.2761 3.5 15.5 3.72386 15.5 4H16.5C16.5 3.17157 15.8284 2.5 15 2.5V3.5ZM9 2.5C8.17157 2.5 7.5 3.17157 7.5 4H8.5C8.5 3.72386 8.72386 3.5 9 3.5V2.5Z"
        fill="#707070"
      />
    </SvgIcon>
  );
}
