import { object, string, infer as Infer, array } from 'zod';

export const LogInFormSchema = object({
  username: string().min(1, { message: 'Username cannot be empty' }),
  password: string().min(1, { message: 'Password cannot be empty' })
});
export type LogInFormType = Infer<typeof LogInFormSchema>;

export const UserSchema = object({
  username: string(),
  firstName: string(),
  lastName: string(),
  role: object({
    name: string(),
    permissions: array(string())
  })
});
export type UserSchemaType = Infer<typeof UserSchema>;
