import { getAllMaintenanceReqs as getAll } from '../../../api/maintenance';
import {
  GET_MAINTENANCE_REQS,
  SET_MAINTENANCE_PAGE,
  SET_MAINTENANCE_PER_PAGE,
  SET_SEARCH_KEYS,
  SET_SEARCH_VALUE
} from './maintenance.constants';

export const getAllMaintenanceReqs = (pageNumber: number, pageSize: number, searchParams?: Record<string, string>) => {
  return {
    type: GET_MAINTENANCE_REQS,
    promise: getAll(pageNumber, pageSize, searchParams)
  };
};

export const setPage = (page: number) => {
  return {
    type: SET_MAINTENANCE_PAGE,
    response: page
  };
};

export const setPerPage = (perPage: number) => {
  return {
    type: SET_MAINTENANCE_PER_PAGE,
    response: perPage
  };
};

export const setSearchValue = (value: string) => {
  return {
    type: SET_SEARCH_VALUE,
    response: value
  };
};

export const setSearchKeys = (value: string | string[]) => {
  return {
    type: SET_SEARCH_KEYS,
    response: value
  };
};
