const promiseMiddleware = () => {
    return (next: (arg0: any) => void) => (action: { [x: string]: any; promise: any; type: any; }) => {

        const { promise, type, ...rest } = action;
    
        if (!promise) return next(action);
    
        const SUCCESS = type + '_SUCCESS';
        const REQUEST = type + '_REQUEST';
        const FAILURE = type + '_FAILURE';
        next({ ...rest, type: REQUEST });

        return promise
            .then((response: any) => {
                next({ ...rest, response: response, type: SUCCESS });
                return true;
            })
            .catch((error: any) => {
                next({ ...rest, error, type: FAILURE });
                return false;
            });
    };
};
export default promiseMiddleware;