import { object, string, infer as Infer, array } from 'zod';

export const timeValidation = string().regex(/^(1[0-2]|0?[1-9]):([0-5]?[0-9]) [AP]M$/, { message: 'Please enter a valid time' });

export const PrincipalSchema = object({
  _id: string(),
  name: string(),
  createdAt: timeValidation,
  updatedAt: timeValidation
});

export type PrincipalSchemaType = Infer<typeof PrincipalSchema>;

export type PrincipalReturnType = {
  principalsList: PrincipalSchemaType[];
  totalCount: {
    count: number;
  };
};

export const PrincipalFormSchema = PrincipalSchema.omit({ createdAt: true, _id: true, updatedAt: true });
export const PrincipalCreateSchema = PrincipalFormSchema;

export type PrincipalFormType = Infer<typeof PrincipalFormSchema>;
export type PrincipalCreateType = Infer<typeof PrincipalCreateSchema>;