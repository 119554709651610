import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box, { BoxProps } from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import type { Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

function Header({ children, onClose }: { children?: string | ReactNode; onClose?: () => void }) {
  return (
    <>
      <DialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" pt={2} pl={2}>
          <Typography id="transition-modal-title" component="h2" fontWeight={600}>
            {children}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: (theme) => theme.palette.text.primary }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider component="div" />
    </>
  );
}

function Footer({ onClose, onOk, ...props }: { onClose?: () => void; onOk?: () => void; props?: BoxProps }) {
  const mobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <>
      <Divider component="div" />
      <DialogActions className="pb-4 pe-4">
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" py={2} pr={2}>
          <Stack direction={mobileScreen ? 'column' : 'row'} gap={4} width={mobileScreen ? '100%' : 'auto'}>
            <Button
              onClick={onClose}
              variant="outlined"
              color="primary"
              className="text-capitalize text-nowrap"
              sx={(theme) => ({
                width: 134,
                height: 50,
                borderRadius: 2,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              })}>
              Cancel
            </Button>
            <Button
              onClick={onOk}
              variant="contained"
              color="primary"
              className="text-capitalize text-nowrap"
              disableElevation
              sx={(theme) => ({
                width: 134,
                height: 50,
                borderRadius: 2,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              })}>
              OK
            </Button>
          </Stack>
        </Box>
      </DialogActions>
    </>
  );
}

function ConfirmDialog({ open, onClose, children, ...props }: { children?: ReactNode; onClose: () => void; open: boolean }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Fade in={open}>
        <Box
          sx={(theme) => ({
            borderRadius: 2,
            alignSelf: 'center',
            justifySelf: 'center',
            [theme.breakpoints.down('md')]: {
              width: '100%'
            }
          })}>
          <DialogContentText>{children}</DialogContentText>
        </Box>
      </Fade>
    </Dialog>
  );
}

export default Object.assign(ConfirmDialog, { Header, Footer });
