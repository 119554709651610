import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '~/redux/hooks';
import { logout } from '~/api/user';

export default function Header({ ...props }) {
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.user);
  const { userInfo } = userState;
  const [location, setLocation] = React.useState('1');
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setLocation(event.target.value as string);
  };

  const locations = [
    { id: '1', name: 'Paradip' },
    { id: '2', name: 'Talcher' }
  ];

  function menuToggle() {
    const toggleMenu = document.querySelector('.menu') ?? false;
    if (toggleMenu) {
      toggleMenu.classList.toggle('active');
    }
  }

  async function handleLogOut() {
    setLoading(true);
    const logoutResult = await logout();
    if (logoutResult === 200) {
      navigate('/auth/login');
      return;
    }
    alert('Something went wrong!');
    setLoading(false);
  }

  return (
    <div className="header sticky" style={{ height: 90 }}>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          {/* <LocationOnIcon className="me-3" />
          <FormControl style={{ width: '200px' }}>
            <Select //
              labelId="locationSelect"
              id="locationSelect"
              value={location}
              IconComponent={KeyboardArrowDownIcon}
              label="Location"
              variant="standard"
              onChange={handleChange}>
              {locations.map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </div>
        <div className="action">
          <div className="profile" onClick={menuToggle}>
            <div className="avatar">
              <img alt="image1" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" />
            </div>
            <div className="d-flex flex-column me-3">
              <Typography variant="body1">
                <strong>{userInfo ? userInfo.firstName + ' ' + userInfo.lastName : ''}</strong>
              </Typography>
              <Typography variant="caption">{userInfo?.role?.name || ''}</Typography>
            </div>
            <div className="d-flex align-items-center">
              <KeyboardArrowDownIcon />
            </div>
          </div>
          <div className="menu">
            <ul>
              {/* <li>
                <a href="#!">My profile</a>
              </li>
              <li>
                <a href="#!">Edit profile</a>
              </li>
              <li>
                <a href="#!">Inbox</a>
              </li>
              <li>
                <a href="#!">Setting</a>
              </li>
              <li>
                <a href="#!">Help</a>
              </li> */}
              <li>
                <a href="#!" onClick={!loading ? handleLogOut : () => {}}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
