import { Equipment } from '~/models/Equipment';
import { MechanicSchemaType } from '~/models/Mechanic';
import { PrincipalSchemaType } from '~/models/Principal';
import { VesselSchemaType } from '~/models/Vessel';
import { Requisition, RequisitionLog } from '../../../models/Requisition';
import {
  GET_REQUISITIONS_FAILURE,
  GET_REQUISITIONS_REQUEST,
  GET_REQUISITIONS_SUCCESS,
  GET_REQUISITION_LOG_REQUEST,
  GET_REQUISITION_LOG_FAILURE,
  GET_REQUISITION_LOG_SUCCESS
} from './requisition.constants';

export type RequisitionListState = Requisition & {
  log: RequisitionLog[];
  equipment: Equipment[];
  givenBy: MechanicSchemaType[];
  receivedBy: MechanicSchemaType[];
  operator: MechanicSchemaType[];
  vessel: VesselSchemaType[];
  principal: PrincipalSchemaType[];
};

interface RequisitionState {
  requisitionList: { requisitionList: RequisitionListState[]; totalCount: { count: number } };
  loading: boolean;
  page: number;
  perPage: number;
}

const initialState: RequisitionState = {
  requisitionList: { requisitionList: [], totalCount: { count: 0 } },
  loading: false,
  page: 0,
  perPage: 5
};

const requisitionReducer = (state = initialState, action: { response?: any; type?: any; requisitionId?: number }): RequisitionState => {
  const { type } = action;

  switch (type) {
    case GET_REQUISITIONS_REQUEST:
      return { ...state, loading: true };
    case GET_REQUISITIONS_SUCCESS: {
      return {
        ...state,
        requisitionList: action.response,
        loading: false
      };
    }
    case GET_REQUISITIONS_FAILURE:
      return { ...state, loading: false };

    case GET_REQUISITION_LOG_REQUEST:
      return {
        ...state,
        requisitionList: {
          ...state.requisitionList,
          requisitionList: state.requisitionList?.requisitionList?.map((resq: any) => {
            if (resq?.id !== action?.requisitionId) {
              return resq;
            }
            return {
              ...resq,
              log: { loading: true }
            };
          })
        }
      };
    case GET_REQUISITION_LOG_SUCCESS:
      return {
        ...state,
        requisitionList: {
          ...state.requisitionList,
          requisitionList: state.requisitionList?.requisitionList?.map((resq: any) => {
            if (resq?.id !== action?.requisitionId) {
              return resq;
            }
            return {
              ...resq,
              log: { loading: false, data: action.response }
            };
          })
        }
      };
    case GET_REQUISITION_LOG_FAILURE:
      return {
        ...state,
        requisitionList: {
          ...state.requisitionList,
          requisitionList: state.requisitionList?.requisitionList?.map((resq: any) => {
            if (resq?.id !== action?.requisitionId) {
              return resq;
            }
            return {
              ...resq,
              log: { loading: false }
            };
          })
        }
      };

    default:
      return state;
  }
};

export default requisitionReducer;
