import { axiosPrivate } from './axios';
import { AxiosError, AxiosResponse } from 'axios';
import { PlaceOfWorkCreateType } from '~/models/PlaceOfWork';

export const getAllPlaceOfWorks = (pageNumber: number = 0, pageSize: number = 5) => {
  return axiosPrivate
    .get(`/placeofwork?page=${pageNumber}&perPage=${pageSize}`)
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const addPlaceOfWork = (placeOfWorkData: PlaceOfWorkCreateType) => {
  return axiosPrivate
    .post('/placeofwork', placeOfWorkData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};
