import React, { Suspense, useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import EditIcon from '../../../../assets/EditIcon';
import TrashIcon from '../../../../assets/TrashIcon';
import Chip from '@mui/material/Chip';
import { LubricantTableSchemaType } from '../../../../models/Lubricant';
import { formatDateString } from '~/utils/date-time-utils';
import { deleteLubricant } from '~/api/lubricant';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getAllLubricants, setPage } from '../../redux/lubricant.actions';

const LubricantForm = React.lazy(() => import('../LubricantForm'));
const LubricantStockInForm = React.lazy(() => import('../LubricantStockInForm'));
const LubricantStockOutForm = React.lazy(() => import('../LubricantStockOutForm'));
const OSLConfirmationDialog = React.lazy(() => import('../../../../components/ComfirmationDialog'));

export default function LubricantListTableRow({ lubricant }: { lubricant: LubricantTableSchemaType }) {
  const lubricantState = useAppSelector((state) => state.lubricant);
  const { page, perPage } = lubricantState;
  const dispatch = useAppDispatch();
  const [showLubricantForm, setShowLubricantForm] = useState(false);
  const [showLubricantStockInForm, setShowLubricantStockInForm] = useState(false);
  const [showLubricantStockOutForm, setShowLubricantStockOutForm] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleCloseLubricantForm = () => {
    setShowLubricantForm(false);
  };

  const handleOpenLubricantForm = () => {
    setShowLubricantForm(true);
  };

  const handleCloseLubricantStockInForm = () => {
    setShowLubricantStockInForm(false);
  };

  const handleOpenLubricantStockInForm = () => {
    setShowLubricantStockInForm(true);
  };

  const handleCloseLubricantStockOutForm = () => {
    setShowLubricantStockOutForm(false);
  };

  const handleOpenLubricantStockOutForm = () => {
    setShowLubricantStockOutForm(true);
  };

  const handleDeleteLubricant = async () => {
    const result = await deleteLubricant(lubricant?._id);

    if (result === 'success') {
      if (page === 0) {
        dispatch(getAllLubricants(page, perPage));
      } else {
        dispatch(setPage(0));
      }
      setShowConfirmationDialog(false);
    }
    console.error('Something went wrong');
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography color="text.secondary">{lubricant?.code}</Typography>
        </TableCell>

        <TableCell>
          <Typography color="text.secondary">{lubricant?.name}</Typography>
        </TableCell>

        <TableCell>
          <Typography color="text.secondary">{lubricant?.type?.name}</Typography>
        </TableCell>

        <TableCell align="center">
          <Typography color="text.secondary">{lubricant?.stockQty + lubricant?.unitOfMeasurement}</Typography>
        </TableCell>

        <TableCell align="center">
          <Typography color="text.secondary">{lubricant?.grade}</Typography>
        </TableCell>

        <TableCell align="center">
          <Typography color="text.secondary">{lubricant?.log ? formatDateString(lubricant?.log?.lastStockIn?.createdAt) : '-'}</Typography>
        </TableCell>

        <TableCell align="center">
          <Typography color="text.secondary">{lubricant?.log ? lubricant?.log?.lastStockIn?.qty : '-'}</Typography>
        </TableCell>

        <TableCell align="center">
          <Chip
            // label={lubricant?.lubricant?.threshold}
            // color={lubricant?.lubricant?.threshold === 'Warning' ? 'warning' : lubricant.threshold === 'Safe' ? 'success' : 'error'}
            label="Warning"
            color="warning"
            size="small"
          />
        </TableCell>

        <TableCell align="center">
          <Stack spacing={1} alignItems="center">
            <Button
              className="text-capitalize text-nowrap"
              variant="contained"
              color="info"
              size="small"
              onClick={handleOpenLubricantStockInForm}
              sx={{
                width: 70,
                fontWeight: 700,
                fontSize: '10px'
              }}>
              Stock In
            </Button>
            <Button
              className="text-capitalize text-nowrap"
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpenLubricantStockOutForm}
              sx={{
                width: 70,
                fontWeight: 700,
                fontSize: '10px'
              }}>
              Stock Out
            </Button>
          </Stack>
        </TableCell>

        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <IconButton aria-label="edit" onClick={handleOpenLubricantForm}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => setShowConfirmationDialog(true)}>
              <TrashIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <Suspense>
        {showLubricantForm && <LubricantForm mode="edit" data={lubricant} isOpen handleClose={handleCloseLubricantForm} />}

        {showLubricantStockInForm && (
          <LubricantStockInForm mode="edit" data={lubricant} isOpen handleClose={handleCloseLubricantStockInForm} />
        )}

        {showLubricantStockOutForm && (
          <LubricantStockOutForm mode="edit" data={lubricant} isOpen handleClose={handleCloseLubricantStockOutForm} />
        )}

        {showConfirmationDialog && (
          <OSLConfirmationDialog
            open
            title={`Delete Lubricant`}
            onCancel={() => setShowConfirmationDialog(false)}
            onOk={handleDeleteLubricant}>
            <Typography>{`Do you want to delete Lubricant: ${lubricant?.name}?`}</Typography>
          </OSLConfirmationDialog>
        )}
      </Suspense>
    </>
  );
}
