import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import OSLModal from '../../../components/Modal';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { MaintenanceSchemaType, MaintenanceFormType, MaintenanceFormSchema } from '../../../models/Maintenance';
import OSLForm from '../../../components/Form';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getAllMaintenanceReqs, setPage } from '../redux/maintenance.actions';
import { addMaintenance, updateMaintenance } from '~/api/maintenance';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormHelperText from '@mui/material/FormHelperText';

export const MaintenanceStatuses: {
  title: string;
  color: 'primary' | 'warning' | 'success' | 'default' | 'secondary' | 'error' | 'info' | 'cancelled' | undefined;
}[] = [
  {
    title: 'Todo',
    color: 'primary'
  },
  {
    title: 'In Progress',
    color: 'warning'
  },
  {
    title: 'Done',
    color: 'success'
  },
  {
    title: 'Cancelled',
    color: 'cancelled'
  }
];

export default function MaintenanceForm({
  isOpen,
  handleClose,
  mode,
  data
}: {
  isOpen: boolean;
  handleClose: () => void;
} & ({ mode: 'edit'; data: MaintenanceSchemaType } | { mode: 'create'; data?: never })) {
  const [loading, setLoading] = useState(false);
  const maintenanceState = useAppSelector((state) => state.maintenance);
  const { page, perPage } = maintenanceState;
  const dispatch = useAppDispatch();
  const mobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<MaintenanceFormType>({
    mode: 'onChange',
    resolver: zodResolver(MaintenanceFormSchema),
    defaultValues:
      mode === 'edit'
        ? {
            number: data?.number || '',
            service: data?.service,
            type: data?.type,
            detail: data?.detail || '',
            status: data?.status || ''
          }
        : {
            number: '',
            detail: '',
            status: MaintenanceStatuses[0].title
          }
  });

  const onSubmit: SubmitHandler<MaintenanceFormType> = async (submitData) => {
    setLoading(true);
    const formData = {
      number: submitData.number,
      service: submitData.service,
      type: submitData.type,
      detail: submitData.detail,
      status: submitData.status
    };
    let result;

    if (mode === 'create') {
      result = await addMaintenance(formData);
    }

    if (mode === 'edit') {
      result = await updateMaintenance({ ...formData, maintenanceId: data?._id });
    }

    if (result === 'success') {
      if (page === 0) {
        dispatch(getAllMaintenanceReqs(page, perPage));
      } else {
        dispatch(setPage(0));
      }
      setLoading(false);
      handleClose();
      return;
    }
    setLoading(false);
    console.error('Something went wrong');
  };

  return (
    <>
      <OSLModal onClose={handleClose} open={isOpen}>
        <OSLModal.Header onClose={handleClose}>{mode === 'edit' ? 'Edit' : 'Add'} Maintenance Requisition</OSLModal.Header>
        <Box px={5} py={4}>
          <OSLForm onSubmit={handleSubmit(onSubmit)} id="maintenance-requisition-form">
            <OSLForm.FormField label="Maintenance Req. No.">
              <Controller
                name="number"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="outlined"
                    error={!!errors.number}
                    helperText={errors.number?.message}
                    fullWidth
                    inputRef={ref}
                    size="small"
                    placeholder="Enter Maintenance Req. No."
                    {...field}
                  />
                )}
              />
            </OSLForm.FormField>

            <OSLForm.FormField label="Service Req. No.">
              <Controller
                name="service"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="outlined"
                    error={!!errors.service}
                    helperText={errors.service?.message}
                    fullWidth
                    inputRef={ref}
                    size="small"
                    placeholder="Enter Service Req. No."
                    {...field}
                  />
                )}
              />
            </OSLForm.FormField>

            <OSLForm.FormField label="Details">
              <Controller
                name="detail"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="outlined"
                    error={!!errors.detail}
                    helperText={errors.detail?.message}
                    fullWidth
                    inputRef={ref}
                    size="small"
                    placeholder="Enter Details"
                    multiline
                    minRows={3}
                    {...field}
                  />
                )}
              />
            </OSLForm.FormField>

            <OSLForm.FormField label="Type">
              <Controller
                name="type"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="outlined"
                    error={!!errors.type}
                    helperText={errors.type?.message}
                    fullWidth
                    inputRef={ref}
                    size="small"
                    placeholder="Enter Type"
                    {...field}
                  />
                )}
              />
            </OSLForm.FormField>

            <OSLForm.FormField label="Status">
              <Controller
                name="status"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => {
                  return (
                    <FormControl component="fieldset" error={!!errors.status}>
                      <RadioGroup
                        row
                        aria-labelledby="ownership"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        {...field}>
                        {MaintenanceStatuses.map((o, i) => (
                          <FormControlLabel
                            key={o.title}
                            value={o.title}
                            control={<Radio size="small" />}
                            label={<Typography variant="body2">{o.title}</Typography>}
                          />
                        ))}
                      </RadioGroup>
                      <FormHelperText>{errors.status?.message}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
            </OSLForm.FormField>
          </OSLForm>
        </Box>

        <OSLModal.Footer justifyContent={mobileScreen ? 'start' : 'right'}>
          <Stack direction={mobileScreen ? 'column' : 'row'} gap={4} width={mobileScreen ? '100%' : 'auto'}>
            <Button
              aria-label="cancel"
              onClick={handleClose}
              variant="outlined"
              color="primary"
              className="text-capitalize text-nowrap"
              sx={(theme) => ({
                width: 134,
                height: 50,
                borderRadius: 2,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              })}>
              <Typography>Cancel</Typography>
            </Button>
            <Button
              aria-label="save"
              variant="contained"
              color="primary"
              disableElevation
              className="text-capitalize text-nowrap"
              sx={(theme) => ({
                width: 134,
                height: 50,
                borderRadius: 2,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              })}
              type="submit"
              disabled={loading}
              form="maintenance-requisition-form">
              <Typography>Save</Typography>
            </Button>
          </Stack>
        </OSLModal.Footer>
      </OSLModal>
    </>
  );
}
