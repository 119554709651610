import { AxiosError, AxiosResponse } from 'axios';
import axios, { axiosPrivate } from './axios';

export const login = (username: string, password: string) => {
  return axios
    .post(
      '/user/login',
      {
        username,
        password
      },
      {
        withCredentials: true
      }
    )
    .then((reponse: AxiosResponse) => reponse.status)
    .catch((err: AxiosError) => {
      if (err.response?.status === 401) {
        return { error: 'Incorrect Username or Password' };
      }
      return { error: 'There is something wrong' };
    });
};

export const logout = () => {
  return axiosPrivate
    .post('/user/logout')
    .then((response: AxiosResponse) => response.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const getCurrentUser = () => {
  return axiosPrivate
    .get('/user/me')
    .then((response: AxiosResponse) => response)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const getCurrenSession = () => {
  return axiosPrivate
    .get('/user/getSession')
    .then((response: AxiosResponse) => response)
    .catch((err: AxiosError) => {
      return err;
    });
};
