import React, { useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import EditIcon from '../../../../assets/EditIcon';
import TrashIcon from '../../../../assets/TrashIcon';
import Avatar from '@mui/material/Avatar';
import VehicleListTable from './VehicleListTable';
import Collapse from '@mui/material/Collapse';
import { EquipmentListState } from '../../redux/equipment.reducer';
import CircleIcon from '@mui/icons-material/Circle';
import Box from '@mui/material/Box';
import ConfirmDialog from '~/components/ConfirmDialog';
import { Equipment } from '~/models/Equipment';
import { setPage, getAllEquipments } from '../../redux/equipment.actions';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { deleteEquipment } from '~/api/equipment';

export default function EquipmentListTableRow({ equipment }: { equipment: EquipmentListState }) {
  const dispatch = useAppDispatch();
  const equipmentState = useAppSelector((state) => state.equipment);
  const { page, perPage } = equipmentState;
  const [showVehicleTable, setShowVehicleTable] = useState<boolean>(false);
  const [showConfirmDeleteEquipment, setShowConfirmDeleteEquipment] = useState<boolean>(false);
  const [equipmentData, setEquipmentData] = useState<Equipment>();
  const vehicleStatus = useMemo(
    () =>
      equipment?.vehicles?.reduce(
        (status, vh) => {
          if (vh?.availability) {
            return {
              ...status,
              free: status?.free + 1
            };
          }
          return {
            ...status,
            active: status?.active + 1
          };
        },
        { free: 0, active: 0 }
      ),
    [equipment]
  );

  const handleShowVehicleTable = () => {
    setShowVehicleTable((isShowing) => !isShowing);
  };

  const handleShowConfirmDeleteEquipment = (equipment: Equipment) => {
    setEquipmentData(equipment);
    setShowConfirmDeleteEquipment(true);
  };

  const handleDeleteEquipment = async () => {
    const result = await deleteEquipment({
      equipmentId: equipmentData?._id ?? ''
    });

    if (result === 'success') {
      if (page === 0) {
        dispatch(getAllEquipments(page, perPage));
      } else {
        dispatch(setPage(0));
      }
      setShowConfirmDeleteEquipment(false);
      return;
    }
    console.error('Something went wrong');
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography color="text.secondary">{equipment?.code}</Typography>
        </TableCell>

        <TableCell>
          <Stack direction="row" gap={1} alignItems="center">
            <Avatar alt={equipment.name} src={equipment.avatar} sx={{ width: 36, height: 36 }} />
            <Typography color="text.secondary">{equipment?.name}</Typography>
          </Stack>
        </TableCell>

        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center" gap="20px">
            <Box component="div" gap="10px" display="flex" alignItems="center">
              <CircleIcon color="success" sx={{ fontSize: 10 }} />
              <Typography color="text.secondary">{vehicleStatus?.free}</Typography>
            </Box>
            <Box component="div" gap="10px" display="flex" alignItems="center">
              <CircleIcon color="error" sx={{ fontSize: 10 }} />
              <Typography color="text.secondary">{vehicleStatus?.active}</Typography>
            </Box>
          </Stack>
        </TableCell>

        <TableCell align="center">
          <Button
            className="text-capitalize text-nowrap"
            variant="contained"
            color="info"
            size="small"
            onClick={handleShowVehicleTable}
            sx={{
              width: 70,
              fontWeight: 700,
              fontSize: '10px'
            }}>
            {showVehicleTable ? 'Close' : 'View All'}
          </Button>
        </TableCell>

        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            {/* TODO: Need to verify edit equipment screen */}
            {/* <IconButton aria-label="edit">
              <EditIcon />
            </IconButton> */}
            <IconButton aria-label="delete" onClick={() => handleShowConfirmDeleteEquipment(equipment)}>
              <TrashIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          sx={{
            padding: 0,
            border: 0,
            borderLeft: 3,
            borderLeftColor: showVehicleTable ? 'primary.main' : 'transparent',
            boxShadow: 'none'
          }}
          colSpan={5}>
          <Collapse in={showVehicleTable} timeout="auto" unmountOnExit>
            <VehicleListTable
              vehicleList={equipment?.vehicles}
              aria-label="vehicles table"
              sx={{
                width: '100%'
              }}
            />
          </Collapse>
        </TableCell>
      </TableRow>

      <ConfirmDialog open={showConfirmDeleteEquipment} onClose={() => setShowConfirmDeleteEquipment(false)}>
        <ConfirmDialog.Header onClose={() => setShowConfirmDeleteEquipment(false)}>Confirm delete equipment</ConfirmDialog.Header>
        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" p={5}>
          <Typography variant="body1">
            Are you sure you want to delete equipment <strong>{equipmentData?.name}</strong> ?
          </Typography>
        </Box>
        <ConfirmDialog.Footer onClose={() => setShowConfirmDeleteEquipment(false)} onOk={() => handleDeleteEquipment()} />
      </ConfirmDialog>
    </>
  );
}
