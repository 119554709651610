import React from 'react';
import ProgressItem from '../Components/ProgressItem';
import PieChart from '../Components/PieChart';
import BarChart from '../Components/BarChart';
import GanttChart from '../Components/GanttChart';
import Grid from '@mui/material/Grid';
import MainScreen from '../../MainScreen/View/MainScreen';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SelectButton from '~/components/SelectButton';

function FunctionalButton({ title, startIcon, onClick }: { title: string; startIcon: React.ReactNode; onClick: () => void }) {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      className="text-capitalize text-nowrap"
      disableElevation
      endIcon={<KeyboardArrowDownIcon />}
      startIcon={startIcon}
      sx={(theme) => ({
        minWidth: 134,
        height: 50,
        borderRadius: 2,
        [theme.breakpoints.down('md')]: {
          width: '100%'
        }
      })}>
      {title}
    </Button>
  );
}

export function Dashboard({ ...props }) {
  const turnaroundData = {
    labels: ['AJ Loren', 'Saswath M.', 'Rakesh B.', 'Suraj S.', 'Ganesh S.', 'Manas B.'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [3, 7, 5, 2, 8, 3],
        backgroundColor: '#fd853a'
      }
    ]
  };

  const lubricantData = {
    labels: ['LUB-123', 'LUB-018', 'LUB-706', 'LUB-984', 'LUB-071', 'LUB-046', 'LUB-025', 'LUB-375', 'LUB-045', 'LUB-362'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [5, 0.25, 2.5, 10, 2.25, 7, 8, 0.1, 6, 3],
        backgroundColor: '#2ed480'
      }
    ]
  };

  const bottomVehicleData = {
    labels: ['CAT-245', 'CAT-246', 'CAT-247', 'CAT-248', 'CAT-249'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [
          ['2022-06-12', '2022-06-13'],
          ['2022-06-13', '2022-06-16'],
          ['2022-06-16', '2022-06-17'],
          ['2022-06-17', '2022-06-18'],
          ['2022-06-18', '2022-06-19']
        ],
        backgroundColor: '#2398ed'
      }
    ]
  };

  return (
    <MainScreen
      hasPaperWrap={false}
      title="Dashboard"
      buttons={[
        <FunctionalButton startIcon={<CalendarMonthIcon />} title="Date Range" onClick={() => {}} />,
        <FunctionalButton startIcon={<AccessTimeIcon />} title="Shift" onClick={() => {}} />,
        <FunctionalButton startIcon={<DirectionsBoatIcon />} title="Principal" onClick={() => {}} />,
        <FunctionalButton startIcon={<LocalGasStationIcon />} title="Lubricant Part No." onClick={() => {}} />,
        <FunctionalButton startIcon={<EngineeringIcon />} title="Operator" onClick={() => {}} />,
      ]}>
      <div style={{ backgroundColor: '#f3f5f7' }}>
        <div className="d-flex align-items-center justify-content-around mb-3 flex-wrap">
          <ProgressItem //
            circularColor="info"
            label="Avg. Active Time"
            description="23 Hrs/day"
            circularValue={75}
          />
          <ProgressItem //
            circularColor="warning"
            label="Avg. Fuel Efficiency"
            description="7 Ltr/hr"
            circularValue={80}
          />
          <ProgressItem //
            circularColor="success"
            label="Avg. Marching Time"
            description="35 mins"
            circularValue={50}
          />
          <ProgressItem //
            circularColor="error"
            label="Avg. Turnaround Time"
            description="26 Hours"
            circularValue={45}
          />
          <ProgressItem //
            circularColor="secondary"
            label="Monthly Fuel Consumed"
            description="430 Ltr/day"
            circularValue={60}
          />
        </div>
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item sm={12} lg={7}>
            <GanttChart title="Bottom 5 Vehicle Activity" data={bottomVehicleData} />
          </Grid>
          <Grid item sm={12} lg={5}>
            <PieChart />
          </Grid>
          <Grid item sm={12} lg={6}>
            <BarChart title="Turnaround Time for Operator" data={turnaroundData} />
          </Grid>
          <Grid item sm={12} lg={6}>
            <BarChart title="Lubricant Against Consumption" data={lubricantData} />
          </Grid>
        </Grid>
      </div>
    </MainScreen>
  );
}
