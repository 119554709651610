import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M8 20.0001V20.5001C8.13261 20.5001 8.25979 20.4474 8.35355 20.3536L8 20.0001ZM4 20.0001H3.5C3.5 20.2762 3.72386 20.5001 4 20.5001V20.0001ZM4 16.0001L3.64645 15.6465C3.55268 15.7403 3.5 15.8675 3.5 16.0001H4ZM15.2929 4.7072L15.6464 5.06075V5.06075L15.2929 4.7072ZM16.7071 4.7072L16.3536 5.06075V5.06075L16.7071 4.7072ZM19.2929 7.29299L19.6464 6.93943V6.93943L19.2929 7.29299ZM19.2929 8.7072L18.9393 8.35365L19.2929 8.7072ZM8 19.5001H4V20.5001H8V19.5001ZM4.5 20.0001V16.0001H3.5V20.0001H4.5ZM4.35355 16.3536L15.6464 5.06075L14.9393 4.35365L3.64645 15.6465L4.35355 16.3536ZM16.3536 5.06075L18.9393 7.64654L19.6464 6.93943L17.0607 4.35365L16.3536 5.06075ZM18.9393 8.35365L7.64645 19.6465L8.35355 20.3536L19.6464 9.06075L18.9393 8.35365ZM18.9393 7.64654C19.1346 7.8418 19.1346 8.15838 18.9393 8.35365L19.6464 9.06075C20.2322 8.47497 20.2322 7.52522 19.6464 6.93943L18.9393 7.64654ZM15.6464 5.06075C15.8417 4.86549 16.1583 4.86549 16.3536 5.06075L17.0607 4.35365C16.4749 3.76786 15.5251 3.76786 14.9393 4.35365L15.6464 5.06075Z"
        fill="#707070"
      />
      <path
        d="M12 8L16 12"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
