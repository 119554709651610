import { getAllLubricants as getAll } from '../../../api/lubricant';
import { GET_LUBRICANTS, SET_LUBRICANT_PAGE, SET_LUBRICANT_PER_PAGE } from './lubricant.constants';

export const getAllLubricants = (pageNumber: number, pageSize: number) => {
  return {
    type: GET_LUBRICANTS,
    promise: getAll(pageNumber, pageSize)
  };
};

export const setPage = (page: number) => {
  return {
    type: SET_LUBRICANT_PAGE,
    response: page
  };
};

export const setPerPage = (perPage: number) => {
  return {
    type: SET_LUBRICANT_PER_PAGE,
    response: perPage
  };
};
