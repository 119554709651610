import { createTheme, PaletteOptions } from '@mui/material/styles';
import variables from './variables.module.scss';

export const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 15,
    body1: {
      fontSize: 15
    },
    body2: {
      fontSize: 12
    }
  },
  palette: {
    primary: {
      main: variables.primary_color,
      contrastText: variables.white_color
    },
    info: {
      main: variables.info_color,
      contrastText: variables.white_color
    },
    error: {
      main: variables.error_color,
      contrastText: variables.white_color
    },
    warning: {
      main: variables.warning_color,
      contrastText: variables.white_color
    },
    success: {
      main: variables.success_color,
      contrastText: variables.white_color
    },
    cancelled: {
      main: variables.black_color,
      contrastText: variables.white_color
    },
    text: {
      primary: variables.black_color,
      secondary: variables.gray_color
    },
    divider: variables.divider_color,
    border: {
      main: variables.border_color
    }
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderBottom: `1px solid ${theme.palette.divider}`
        })
      }
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius
        }),
        sizeSmall: {
          width: 70
        },
        label: {
          fontWeight: 700,
          lineHeight: '50px'
        },
        labelSmall: {
          fontSize: 10,
          padding: 0
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: `calc(${theme.shape.borderRadius}px * 2)`,
          ':hover .MuiOutlinedInput-notchedOutline': {
            borderColor: variables.divider_color
          },
          fontSize: 15
        }),
        multiline: {
          padding: 0
        },
        inputSizeSmall: {
          padding: '13px 15px',
          fontSize: 12
        },
        notchedOutline: {
          borderWidth: 2,
          borderColor: variables.border_color
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: `calc(${theme.shape.borderRadius}px * 2)`,
          ':hover .MuiOutlinedInput-notchedOutline': {
            borderColor: variables.divider_color
          },
          fontSize: 15
        }),
        inputRoot: {
          fontSize: 12,
          paddingTop: 5.5,
          paddingBottom: 5.5,
          paddingLeft: 7
        },
        option: {
          fontSize: 12,
          color: variables.black_color
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: variables.border_color
        }
      }
    }
  }
});
