import { AxiosError, AxiosResponse } from 'axios';
import { axiosPrivate } from './axios';
import {
  DamagedCreateType,
  SparePartStockInCreateType,
  SparePartStockOutCreateType,
  SparePartCreateType,
  SparePartEditType
} from '~/models/SparePart';

export const getAllSpareParts = (pageNumber: number = 0, pageSize: number = 5) => {
  return axiosPrivate
    .get(`/sparepart?page=${pageNumber}&perPage=${pageSize}`)
    .then((response: AxiosResponse) => response.data)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const addSparePart = (sparePartData: SparePartCreateType) => {
  return axiosPrivate
    .post('/sparePart', sparePartData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const updateSparePart = (sparePartData: SparePartEditType) => {
  return axiosPrivate
    .patch('/sparePart', sparePartData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const deleteSparePart = (sparePartId: string) => {
  return axiosPrivate
    .delete(`/sparepart/${sparePartId}`)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const stockIn = (stockInData: SparePartStockInCreateType) => {
  return axiosPrivate
    .post('/sparepart/stockin', stockInData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const stockOut = (stockOutData: SparePartStockOutCreateType) => {
  return axiosPrivate
    .post('/sparepart/stockout', stockOutData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};

export const damaged = (damagedData: DamagedCreateType) => {
  return axiosPrivate
    .post('/sparepart/damaged', damagedData)
    .then((response: AxiosResponse) => response.data.status)
    .catch((err: AxiosError) => {
      return err;
    });
};
