import React from 'react';
import './App.css';
import { PublicRoutes } from './router/index';
import { Provider } from 'react-redux';
import reduxStore from './redux/store/index';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Loader from './components/Loader';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export const reduxPersistStore = persistStore(reduxStore);

const renderLoader = () => {
  return <Loader />;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={reduxStore}>
          <PersistGate
            loading={renderLoader()}
            persistor={reduxPersistStore}
            onBeforeLift={() => new Promise((resolve) => setTimeout(resolve, 1000))}>
            <PublicRoutes />
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
